import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ManageProjects from '../../components/projectManagementComponents/manageProjects';
import MapUser from '../../components/projectManagementComponents/mapUser';
import ProjectDetails from '../../components/projectManagementComponents/projectDetails';
import useProjects from '../../components/hooks/useProjects';
import UpdateProjects from '../../components/modals/manageProjects/updateProjects';
import usePermissions from '../../components/hooks/usePermissions';
import { hasPermission } from '../../util/permissionChecker';
import PermissionModal from '../../components/modals/permission/permissionModal';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { main_api } from '../../api/axiosHelper';
import { pushNotification } from '../../util/notification';
import styled from 'styled-components';
import { Button, Card, Select, DatePicker } from 'antd';
import HeadingComponent from '../../components/headingComponent/heading';
import CustomButton from '../../components/customButton/customButton';
import SearchInput from '../../components/searchInput/SearchInput';
import PaginatedSelect from '../../components/customSelect/paginatedSelect';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import { ticketManagementColumns } from '../../util/antdTableColumns';
import UpdateTicket from '../../components/modals/ticketManagement/updateTicket';
import dayjs from 'dayjs';
import DeletePermissionModal from '../../components/modals/permission/deletePermissionModal';
import {ReactComponent as FilterIcon} from '../../assets/rawSvg/filter_list.svg';
import { ticketStatusOptions } from '../../util/dropdownData';

const { RangePicker } = DatePicker;

export default function TicketManagement() {

  //------------------ Custom Hooks ---------------------//
  
  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
  
  //------------------ State Variables ---------------------//
  const [ticketDataForTable, setTicketDataForTable] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [updateTicketValues, setUpdateTicketValues] = useState(null);

  const [projectOptions, setProjectOptions] = useState([]);
  const [debrisTypeOptions, setDebrisTypeOptions] = useState([]);
  const [subActivityTypeOptions, setSubActivityTypeOptions] = useState([]);
  const [fieldMonitorOptions, setFieldMonitorOptions] = useState([]);
  const [siteMonitorOptions, setSiteMonitorOptions] = useState([]);


  const [debrisTypeOptionsPage, setDebrisTypeOptionsPage] = useState(1);
  const [projectOptionsPage, setProjectOptionsPage] = useState(1);
  const [subActivityTypeOptionsPage, setSubActivityTypeOptionsPage] = useState(1);
  const [fieldMonitorOptionsPage, setFieldMonitorOptionsPage] = useState(1);
  const [siteMonitorOptionsPage, setSiteMonitorOptionsPage] = useState(1);


  const [openFilterPopup, setOpenFilterPopup] = useState(false);

  const [searchedValue, setSearchedValue] = useState('');
  const [projectSelected, setProjectSelected] = useState(null);
  const [debrisTypeSelected, setDebrisTypeSelected] = useState(null);
  const [subActivityTypeSelected, setSubActivityTypeSelected] = useState(null);
  const [fieldMonitorSelected, setFieldMonitorSelected] = useState(null);
  const [siteMonitorSelected, setSiteMonitorSelected] = useState(null);
  const [ticketStatusSelected, setTicketStatusSelected] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [rangePickerValue, setRangePickerValue] = useState([null, null]);
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [selectedProjectForUpdateForm, setSelectedProjectForUpdateForm] = useState(null);
  const [selectedSubActivityForUpdateForm, setSelectedSubActivityForUpdateForm] = useState(null);
  const [selectedDebrisTypeForUpdateForm, setSelectedDebrisTypeForUpdateForm] = useState(null);
  
  const [loadDate, setLoadDate] = useState(null);
  const [disposalDate, setDisposalDate] = useState(null);
  const [loadTime, setLoadTime] = useState(null);
  const [disposalTime, setDisposalTime] = useState(null);

  const [permissionText, setPermissionText] = useState('');
  
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [updateTicketModal, setUpdateTicketModal] = useState(false);
  const [voidTicketModal, setVoidTicketModal] = useState(false);

  //------------------------- Fetch Data --------------------//

  const fetchTickets = async (query = '', page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_TICKET(query)}&page=${page}`)
    .then((response) => {
      const result = response.data.results;
      
      const transformedData = result.map(item => ({
        ...item,
        key: item?.id,
        ticketNumber: item?.id || '---',
        manualTicketNumber: item?.manual_ticket_number || '---',
        subActivity: item?.sub_activity?.name || '---',
        truckType: item?.truck?.type?.type || '---',
        projectName: item?.project?.name || '---',
        fieldMonitorName: item?.field_monitor?.name || '---',
        siteMonitorName: item?.site_monitor?.name || '---',
        createdDate: item?.created_at ? dayjs(item.created_at).format('MM/DD/YYYY') : '---',
        modifiedDate: item?.updated_at ? dayjs(item.updated_at).format('MM/DD/YYYY') : '---',
        status: item?.status || '---', 
      }));
      setTicketDataForTable({
        count: response.data.count,
        data: transformedData,
      });
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, 'error');
    });
  };

  const fetchProjectOptions = async (page = 1) => {
    const query =  `is_active=true&page=${page}`
    main_api.get(adminAPIsEndPoints.LIST_PROJECT(query))
    .then((response) => {
    const result = response.data.results;
    if (response.data.next) {
      const urlObj = new URL(response.data.next);
      const params = new URLSearchParams(urlObj.search);
      const pageNumber = params.get('page');
      setProjectOptionsPage(pageNumber);
    } else {
      setProjectOptionsPage(null);
    }

   
    let options = [...projectOptions, ...result?.map(item => ({
      ...item,
      label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
      value: item.id,
    }))];

    const uniqueOptions = options.reduce((acc, current) => {
      const existing = acc.find(item => item.value === current.value);
      if (existing) {
        // Compare the number of keys and keep the one with more information
        if (Object.keys(current).length > Object.keys(existing).length) {
          return acc.map(item => item.value === current.value ? current : item);
        }
        return acc;
      } else {
        acc.push(current);
        return acc;
      }
    }, []);
  
    setProjectOptions(uniqueOptions);
    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
  };

  const fetchDebrisTypeOptions = async (page = 1) => {
    const query =  `is_active=true&page=${page}`

        main_api.get(adminAPIsEndPoints.LIST_DEBRIS(query))
        .then((response) => {
            const result = response?.data?.results;
            
            if (response.data.next) {
              const urlObj = new URL(response.data.next);
              const params = new URLSearchParams(urlObj.search);
              const pageNumber = params.get('page');
              setDebrisTypeOptionsPage(pageNumber);
            } else {
              setDebrisTypeOptionsPage(null);
            }

            let options = [...debrisTypeOptions, ...result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }))];
           
            const uniqueOptions = options.reduce((acc, current) => {
              const existing = acc.find(item => item.value === current.value);
              if (existing) {
                // Compare the number of keys and keep the one with more information
                if (Object.keys(current).length > Object.keys(existing).length) {
                  return acc.map(item => item.value === current.value ? current : item);
                }
                return acc;
              } else {
                acc.push(current);
                return acc;
              }
            }, []);
            // setSelectedDebrisTypeData(result);
            setDebrisTypeOptions(uniqueOptions);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
  };

  const fetchSubActivityTypeOptions = async (page = 1) => {
    const query =  `is_active=true&page=${page}`
    main_api.get(adminAPIsEndPoints.LIST_SUB_ACTIVITY(query))
    .then((response) => {
    const result = response.data.results;
    if (response.data.next) {
      const urlObj = new URL(response.data.next);
      const params = new URLSearchParams(urlObj.search);
      const pageNumber = params.get('page');
      setSubActivityTypeOptionsPage(pageNumber);
    } else {
      setSubActivityTypeOptionsPage(null);
    }
   
    let options = [...subActivityTypeOptions, ...result?.map(item => ({
      ...item,
      label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
      value: item.id,
    }))];
    const uniqueOptions = options.reduce((acc, current) => {
      const existing = acc.find(item => item.value === current.value);
      if (existing) {
        // Compare the number of keys and keep the one with more information
        if (Object.keys(current).length > Object.keys(existing).length) {
          return acc.map(item => item.value === current.value ? current : item);
        }
        return acc;
      } else {
        acc.push(current);
        return acc;
      }
    }, []);
  
    setSubActivityTypeOptions(uniqueOptions);
    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
  };

  const fetchFieldMonitors = async (page = 1) => {
    const query = `is_active=true&page=${page}`;
    main_api.get(adminAPIsEndPoints.LIST_MONITORS(query))
    .then((response) => {
    const result = response.data.results;
    if (response.data.next) {
      const urlObj = new URL(response.data.next);
      const params = new URLSearchParams(urlObj.search);
      const pageNumber = params.get('page');
      setFieldMonitorOptionsPage(pageNumber);
    } else {
      setFieldMonitorOptionsPage(null);
    }
    let options = [...fieldMonitorOptions, ...result?.map(item => ({
      ...item,
      label: item.name ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : 'Unnamed',
      value: item?.id,
    }))];
    const uniqueOptions = options.reduce((acc, current) => {
      const existing = acc.find(item => item.value === current.value);
      if (existing) {
        // Compare the number of keys and keep the one with more information
        if (Object.keys(current).length > Object.keys(existing).length) {
          return acc.map(item => item.value === current.value ? current : item);
        }
        return acc;
      } else {
        acc.push(current);
        return acc;
      }
    }, []);
    setFieldMonitorOptions(uniqueOptions);
    }).catch((error) => {

    pushNotification(error?.response?.data?.detail, "error");
    });
  };

  const fetchSiteMonitors = async (page = 1) => {
    const query = `is_active=true&page=${page}`;
    main_api.get(adminAPIsEndPoints.LIST_MONITORS(query))
    .then((response) => {
      const result = response.data.results;
      if (response.data.next) {
        const urlObj = new URL(response.data.next);
        const params = new URLSearchParams(urlObj.search);
        const pageNumber = params.get('page');
        setSiteMonitorOptionsPage(pageNumber);
      } else {
        setSiteMonitorOptionsPage(null);
      }
      let options = [...siteMonitorOptions, ...result?.map(item => ({
        label: item.name ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : 'Unnamed',
        value: item.id,
      }))];
      const uniqueOptions = options.reduce((acc, current) => {
        const existing = acc.find(item => item.value === current.value);
        if (existing) {
          // Compare the number of keys and keep the one with more information
          if (Object.keys(current).length > Object.keys(existing).length) {
            return acc.map(item => item.value === current.value ? current : item);
          }
          return acc;
        } else {
          acc.push(current);
          return acc;
        }
      }, []);
      setSiteMonitorOptions(uniqueOptions);
    }).catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
  };
  //------------------ Use Effect ---------------------//

  useEffect(() => {
    fetchTickets();
    fetchProjectOptions();
    fetchDebrisTypeOptions();
    fetchSubActivityTypeOptions();
    fetchFieldMonitors();
    fetchSiteMonitors();
  }, []);

  //------------------ Use Effect for Filters ---------------------//
  useEffect(() => {
    let query = `search=${searchedValue}`;
    
    if (projectSelected!==null) {
      query+=`&project=${projectSelected}`;
    }
    if (debrisTypeSelected!==null) {
      query+=`&debris_type=${debrisTypeSelected}`;
    }
    fetchTickets(query, currentPage);
  }, [projectSelected, debrisTypeSelected, searchedValue]);

  const handleExtraFilters = async () => {
    let query = '';
    if(fieldMonitorSelected){
      query += `&field_monitor=${fieldMonitorSelected}`;
    }
    if(siteMonitorSelected){
      query += `&site_monitor=${siteMonitorSelected}`;
    }
    if (subActivityTypeSelected) {
      query += `&sub_activity=${subActivityTypeSelected}`;
      
    }
    if (ticketStatusSelected) {
      query += `&status=${ticketStatusSelected}`;
      
    }
    if (dateRange[0]) {
      query += `&from_date=${dateRange[0]}`;
      
    }
    if (dateRange[1]) {
      query += `&to_date=${dateRange[1]}`;
    }
    await fetchTickets(query, currentPage);
    setOpenFilterPopup(false);
  }

  //------------------ Event Handlers ---------------------//

  const handleProjectChange = (value) => {
    setProjectSelected(value);
  };

  const handleDebrisTypeChange = (value) => {
    setDebrisTypeSelected(value);
  };

  const handleSubActivityTypeChange = (value) => {
    setSubActivityTypeSelected(value);
  };
  const onDateRangeChange = (dates) => {
    setRangePickerValue(dates);
    dates = dates.map(date => date ? date.format('YYYY-MM-DD') : null);
    setDateRange(dates);
  };

  //------------------ Functions for Update Ticket Modal ---------------------//

  const handleEditRow = (value) => {
    if (!hasPermission(assignedPermissionsArray, 'change_ticket')) {
      setPermissionText("You do not have permission to edit a ticket");
      setPermissionPopUp(true)
    }
    else if (value) {
      setSelectedProjectForUpdateForm(value?.project?.id);
      setSelectedSubActivityForUpdateForm(value?.project?.sub_activity?.id);
      setSelectedDebrisTypeForUpdateForm(value?.debris_type?.id);
      setUpdateTicketValues(value);
      setUpdateTicketModal(true);
    }
  };

  const handleAddRow = () => {
    if (!hasPermission(assignedPermissionsArray, 'add_ticket')) {
      setPermissionText("You do not have permission to add a ticket");
      setPermissionPopUp(true)
    }
    else {
      setUpdateTicketValues(null);
      setUpdateTicketModal(true);
    }
  };

  const handleVoidRow = (value) => {
    if (!hasPermission(assignedPermissionsArray, 'change_ticket')) {
      setPermissionText("You do not have permission to void a ticket");
      setPermissionPopUp(true)
    }
    else if (value) {
      setUpdateTicketValues(value);
      setVoidTicketModal(true);
    }
  };


  //------------------ Add & Edit Ticket ---------------------//

  const handleAddTicket =  (values) => {
    
    // values = {
    //   ...values,
    //   project: selectedProjectForUpdateForm,
    //   sub_activity: selectedSubActivityForUpdateForm,
    //   debris_type: selectedDebrisTypeForUpdateForm,
    //   load_time: loadTime.format("HH:mm:ss"),
    //   disposal_time: disposalTime.format("HH:mm:ss"),
    //   load_date: loadDate.format("YYYY-MM-DD"),
    //   disposal_date: disposalDate.format("YYYY-MM-DD"),
    // }
    values = {
      ...values,
      project: selectedProjectForUpdateForm,
      sub_activity: selectedSubActivityForUpdateForm,
      debris_type: selectedDebrisTypeForUpdateForm,
      load_time: values?.load_time.format("HH:mm:ss"),
      disposal_time: values?.disposal_time.format("HH:mm:ss"),
      load_date: values?.load_date?.format("YYYY-MM-DD"),
      disposal_date: values?.disposal_date?.format("YYYY-MM-DD"),
      addresses: [{address: values.address, latitude: values.latitude, longitude: values.longitude}],
      tipping_fees: values?.tipping_fee,
      unit_of_measure: values?.unit_of_measure,
      attachments: uploadedFiles?.map(item => {
        return { key: item.key, attachment_type: item.attachment_type, type: item.type }
      }),
    }

    main_api.post(adminAPIsEndPoints.ADD_TICKET, values)
    .then((response) => {
      if (response.status === 201) {
        pushNotification("Ticket added successfully", "success");
        fetchTickets();
        setUploadedFiles([]); 
        setUpdateTicketModal(false);
      }
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
  }

  const handleEditTicket =  (values) => {
    // values = {
    //   ...values,
    //   project: selectedProjectForUpdateForm,
    //   sub_activity: selectedSubActivityForUpdateForm,
    //   debris_type: selectedDebrisTypeForUpdateForm,
    //   load_time: loadTime.format("HH:mm:ss"),
    //   disposal_time: disposalTime.format("HH:mm:ss"),
    //   load_date: loadDate.format("YYYY-MM-DD"),
    //   disposal_date: disposalDate.format("YYYY-MM-DD"),
    // }
    values = {
      ...values,
      project: selectedProjectForUpdateForm,
      sub_activity: selectedSubActivityForUpdateForm,
      debris_type: selectedDebrisTypeForUpdateForm,
      load_time: values?.load_time.format("HH:mm:ss"),
      disposal_time: values?.disposal_time.format("HH:mm:ss"),
      load_date: values?.load_date?.format("YYYY-MM-DD"),
      disposal_date: values?.disposal_date?.format("YYYY-MM-DD"),
      addresses: [{address: values.address, latitude: values.latitude, longitude: values.longitude}],
      tipping_fees: values?.tipping_fee,
      unit_of_measure: values?.unit_of_measure,
      attachments: uploadedFiles?.map(item => {
        return { key: item.key, attachment_type: item.attachment_type, type: item.type }
      }),
    }
    
    main_api.put(adminAPIsEndPoints.UPDATE_TICKET(updateTicketValues.id), values)
    .then((response) => {
      if (response.status === 200) {
        pushNotification("Ticket updated successfully", "success");
        const query = '';
        fetchTickets(query, currentPage);
        setUploadedFiles([]);
        setUpdateTicketModal(false);
        
      }
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
  }

  //------------------ Delete Ticket ---------------------//

  const handleVoidTicket = () => {
    main_api.delete(adminAPIsEndPoints.DELETE_TICKET(updateTicketValues.id))
    .then((response) => {
      
      if (response.status === 204) {
        setSearchedValue('');
        setProjectSelected(null);
        setDebrisTypeSelected(null);
        setFieldMonitorSelected(null);
        setSiteMonitorSelected(null);
        setTicketStatusSelected(null);
        setSubActivityTypeSelected(null);
        setDateRange([null, null]);
        setRangePickerValue([null, null]);
        setUpdateTicketValues(null);
        fetchTickets();
        setVoidTicketModal(false);
        pushNotification("Ticket deleted successfully", "success");
        
      }
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
  }


  return (
    <div style={{ marginTop: '10px' }}>
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Heading text="Manage Tickets" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
          <CustomButton btnText={"Add Ticket"} color={"white"} onClick={handleAddRow} />
        </div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"10px"}}>
          <div style={{display:"flex", flexDirection:"row"}}>
            <SearchInputWrapper>
              <SearchInput onBlur={(e) => setSearchedValue(e)} onKeyDown={(e) => setSearchedValue(e)} placeholder={"Search by Ticket Number"} />
            </SearchInputWrapper>
            <PaginatedSelect
              value={projectSelected}
              placeholder={"Select Project"}
              options={projectOptions}
              onChange={handleProjectChange}
              fetchData={fetchProjectOptions}
              pageNumber={projectOptionsPage}
              style={{marginLeft:"8px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
            />
            <PaginatedSelect
              value={debrisTypeSelected}
              placeholder={"Select Debris Type"}
              options={debrisTypeOptions}
              onChange={handleDebrisTypeChange}
              fetchData={fetchDebrisTypeOptions}
              pageNumber={debrisTypeOptionsPage}
              style={{marginLeft:"24px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
            />
            <div
              onClick={() => {
                  setSearchedValue('');
                  setProjectSelected(null);
                  setDebrisTypeSelected(null);
                }
              }
              style={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"90px", height:"40px"}}
            >
              Reset Filters
            </div>
          </div>
          <Button
            icon={<FilterIcon />}
            style={{
              backgroundColor: "white",
              color: "#3669AE",
              border: "1px solid #3669AE",
              width: "85px",
              height: "38px",
              borderRadius: "5px",
              position: "relative",
              top: "15px",
              transition: "background-color 0.3s ease",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
            onClick={() => setOpenFilterPopup(!openFilterPopup) }
          >
            More
          </Button>
            
        {openFilterPopup && (<Card
             title="Filters"
             extra={<span onClick={() => {
                setFieldMonitorSelected(null);
                setSiteMonitorSelected(null);
                setTicketStatusSelected(null);
                setSubActivityTypeSelected(null);
                setDateRange([null, null]);
                setRangePickerValue([null, null]);
             }} style={{ cursor: 'pointer', color: 'red' }}>Reset Filters</span>}
             style={{
               position: 'absolute',
               top: '132px',  // Adjust the top position as needed
               right: '32px', // Adjust the right position as needed
               zIndex: 1000,
               width: '330px', // Set the width of the card
               height: '390px', // Set the height of the card
               boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a shadow for depth
               borderRadius: '8px' // Slightly round the corners
             }}
             bodyStyle={{ padding: '16px' }} // Adjust padding inside the card
            >
              <FilterContainer>

              <PaginatedSelect
                value={fieldMonitorSelected}
                placeholder={"Select Field Monitor"}
                options={fieldMonitorOptions}
                onChange={(e) => {
                  setFieldMonitorSelected(e);
                }}
                fetchData={fetchFieldMonitors}
                pageNumber={fieldMonitorOptionsPage}
                style={{marginBottom: "20px", width:"260px", height:"40px"}}
              />
              <PaginatedSelect
                value={siteMonitorSelected}
                placeholder={"Select Site Monitor"}
                options={siteMonitorOptions}
                onChange={(e) => setSiteMonitorSelected(e)}
                fetchData={fetchSiteMonitors}
                pageNumber={siteMonitorOptionsPage}
                style={{marginBottom: "20px", width:"260px", height:"40px"}}
              />
              <PaginatedSelect
                value={subActivityTypeSelected}
                placeholder={'Select Sub Activity'}
                options={subActivityTypeOptions}
                onChange={handleSubActivityTypeChange}
                fetchData={fetchSubActivityTypeOptions}
                pageNumber={subActivityTypeOptionsPage}
                style={{marginBottom: "20px", width:"260px", height:"40px"}}
              />
              <Select
                value={ticketStatusSelected}
                placeholder={"Select Ticket Status"}
                options={ticketStatusOptions}
                onChange={(e) => setTicketStatusSelected(e)}
                style={{marginBottom: "20px", width:"260px", height:"40px"}}
              />
              <RangePicker value={rangePickerValue} onChange={onDateRangeChange} style={{marginBottom: "20px", width:"260px", height:"40px"}} />
              
              <SaveContainer className="d-flex justify-content-end">
                <CustomButton
                  btnText={"Cancel"}
                  margin="0px 5px"
                  noBackground
                  hideIcon={true}
                  onClick={() => setOpenFilterPopup(false)}
                  />
                <Button onClick={handleExtraFilters} type="primary" style={{height:"40px"}}>Apply Filters</Button>
              </SaveContainer>
                  </FilterContainer>
            </Card>)}
        </div>

        <AntdesignTablePagination 
          columns={ticketManagementColumns({handleEditRow, handleVoidRow})} 
          data={ticketDataForTable.data}
          totalCount={ticketDataForTable.count}
          loadPaginatedData={fetchTickets} 
          allowRowSelection={false}
          tableHeight={450}
          tableWidth={1200} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
    </CustomCard>
    {updateTicketModal && 
      <UpdateTicket 
        isModalOpen={updateTicketModal} 
        title={updateTicketValues ? "Edit Ticket" : "Add New Ticket"} 
        onFinish={updateTicketValues ? handleEditTicket : handleAddTicket  } 
        setModalOpen={setUpdateTicketModal} 
        updateTicketValues={updateTicketValues}
        selectedProjectForUpdateForm={selectedProjectForUpdateForm}
        setSelectedProjectForUpdateForm={setSelectedProjectForUpdateForm}
        selectedSubActivityForUpdateForm={selectedSubActivityForUpdateForm}
        setSelectedSubActivityForUpdateForm={setSelectedSubActivityForUpdateForm}
        selectedDebrisTypeForUpdateForm={selectedDebrisTypeForUpdateForm}
        setSelectedDebrisTypeForUpdateForm={setSelectedDebrisTypeForUpdateForm}
        loadTime={loadTime}
        setLoadTime={setLoadTime}
        disposalTime={disposalTime}
        setDisposalTime={setDisposalTime}
        loadDate={loadDate}
        setLoadDate={setLoadDate}
        disposalDate={disposalDate}
        setDisposalDate={setDisposalDate}
        file={file}
        setFile={setFile}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
   
      />
    }
    {voidTicketModal &&
      <DeletePermissionModal
        isModalOpen={voidTicketModal} 
        title="Void Ticket"
        description='Are you sure you want to void this ticket? This action cannot be undone.' 
        deleteText='Void'
        onDelete={handleVoidTicket} 
        setModalOpen={setVoidTicketModal} 
      />
    }
    {permissionPopUp && (
      <PermissionModal 
        text={permissionText}
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    )}
  </div>
  )
}



const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1570px;
  height: calc(100vh - 40px);
  max-height: 750px;
  margin-top: 40px;
  margin-left: 40px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;

const SearchInputWrapper = styled.div`
  width: 350px;
  margin-left: 5px;
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 0px;
  width: 326px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;


const FilterContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  padding: 10px;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }

`;