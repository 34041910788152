import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import dayjs from 'dayjs';
import { Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Radio, Select, Switch } from "antd";
import { CustomModal } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import { attachmentTypeOptions, countryCodes, disposalSiteTypeOptions } from '../../../util/dropdownData';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as PlusIcon } from "../../../assets/rawSvg/Plus.svg";
import { ReactComponent as CrossIcon } from "../../../assets/rawSvg/Cross.svg";
import CustomUploader from '../../customUploader/customUploader';
import { v4 as uuidv4 } from 'uuid';
import TruckCapacity from './truckCapacity';

export default function UpdateTruck({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    editTruckValues,
    file,
    setFile,
    uploadedFiles,
    setUploadedFiles,
    padding = "20px",
}) {

    const [truckDriverAddress, setTruckDriverAddress] = useState("");
    const [truckDriverAddressLatAndLong, setTruckDriverAddressLatAndLong] = useState([null,null]);
    
    const [truckOwnerAddress, setTruckOwnerAddress] = useState("");
    const [truckOwnerAddressLatAndLong, setTruckOwnerAddressLatAndLong] = useState([null,null]);
    
    const [active, setActive] = useState(editTruckValues?.is_active || true);
    const [certificationStatus, setCertificationStatus] = useState(editTruckValues?.certification_status || true);
    const [disposalSiteType, setDisposalSiteType] = useState(editTruckValues?.type.id || null);
    const [debrisType, setDebrisType] = useState(editTruckValues?.type.id || null);
    const [attachmentType, setAttachmentType] = useState(null);
    const [primeContractorPrefix, setPrimeContractorPrefix] = useState(null);
    const [subContractorPrefix, setSubContractorPrefix] = useState(null);
    const [prefixValue, setPrefixValue] = useState(null);
    const [truckNumber, setTruckNumber] = useState(null);

    const [truckCapacity, setTruckCapacity] = useState(editTruckValues?.bed_capacity || null);

    const [calculateCapacityModal, setCalculateCapacityModal] = useState(false);


    const [driverIsOwner, setDriverIsOwner] = useState(editTruckValues?.is_driver_the_owner);
    const [projectOptions, setProjectOptions] = useState([]);
    const [primeContractorOptions, setPrimeContractorOptions] = useState([]);
    const [subContractorOptions, setSubContractorOptions] = useState([]);
    const [truckTypeOptions, setTruckTypeOptions] = useState([]);

    const [projectSelected, setProjectSelected] = useState(null);
    const [primeContractorSelected, setPrimeContractorSelected] = useState(editTruckValues?.project?.contractor?.id || null);
    const [subContractorSelected, setSubContractorSelected] = useState(null);
    const [truckTypeSelected, setTruckTypeSelected] = useState(null);
    const [primeContractorOptionsPage, setPrimeContractorOptionsPage] = useState(1);
    const [subContractorOptionsPage, setSubContractorOptionsPage] = useState(1);
    const [projectOptionsPage, setProjectOptionsPage] = useState(1);
    const [form] = Form.useForm(); 
   


   
    


    //----------------------------- Fetch Project Options -------------------------//



const fetchProjectOptions = async (query = '', page = 1) => {
  main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&page=${page}`)
    .then((response) => {
      const result = response?.data?.results;

      // Handle pagination
      if (response.data.next) {
        const urlObj = new URL(response.data.next);
        const params = new URLSearchParams(urlObj.search);
        const pageNumber = params.get('page');
        setProjectOptionsPage(pageNumber);
      } else {
        setProjectOptionsPage(null);
      }

       // Generate options array from API response
       let options = [...projectOptions, ...result?.map(project => ({
        ...project,
        label: project.name.charAt(0).toUpperCase() + project.name.slice(1),
        value: project.id,
      }))];


      // Ensure the selected contractor is included at the beginning of the options
      if (editTruckValues?.project) {
        const selectedProject = {
          label: editTruckValues?.project?.name.charAt(0).toUpperCase() + editTruckValues?.project?.name.slice(1),
          value: editTruckValues?.project?.id,
        };

        options = [selectedProject, ...options.filter(item => item.value !== selectedProject.value)];
      }

      // Filter out duplicate options and keep the one with more properties
      const uniqueOptions = options.reduce((acc, current) => {
        const existing = acc.find(item => item.value === current.value);
        if (existing) {
          // Compare the number of keys and keep the one with more information
          if (Object.keys(current).length > Object.keys(existing).length) {
            return acc.map(item => item.value === current.value ? current : item);
          }
          return acc;
        } else {
          acc.push(current);
          return acc;
        }
      }, []);


      setProjectOptions(uniqueOptions);
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
};




//---------------------------- Fetch Contractor Options -------------------------//


const fetchPrimeContractors = async (page = 1) => {
  let query = `is_active=true&page=${page}`;
  main_api.get(adminAPIsEndPoints.LIST_CONTRACTORS(query))
    .then((response) => {
      const result = response.data.results;

      // Handle pagination
      if (response.data.next) {
        const urlObj = new URL(response.data.next);
        const params = new URLSearchParams(urlObj.search);
        const pageNumber = params.get('page');
        setPrimeContractorOptionsPage(pageNumber);
      } else {
        setPrimeContractorOptionsPage(null);
      }

      // Generate options array from API response
      let options = [...primeContractorOptions, ...result?.map(primeContractor => ({
        ...primeContractor,
        label: primeContractor.name.charAt(0).toUpperCase() + primeContractor.name.slice(1),
        value: primeContractor.id,
      }))];

      // Ensure selected prime contractors are included
      if (editTruckValues?.prime_contractor) {
        const selectedContractor = {
          label: editTruckValues?.prime_contractor?.name.charAt(0).toUpperCase() + editTruckValues?.prime_contractor?.name.slice(1),
          value: editTruckValues?.prime_contractor?.id,
        };

        // Ensure the selected contractor is included at the beginning of the options
        options = [selectedContractor, ...options.filter(item => item.value !== selectedContractor.value)];

      }
      const uniqueOptions = options.reduce((acc, current) => {
        const existing = acc.find(item => item.value === current.value);
        if (existing) {
          // Compare the number of keys and keep the one with more information
          if (Object.keys(current).length > Object.keys(existing).length) {
            return acc.map(item => item.value === current.value ? current : item);
          }
          return acc;
        } else {
          acc.push(current);
          return acc;
        }
      }, []);


      setPrimeContractorOptions(uniqueOptions);
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
};


const fetchSubContractors = async (page = 1) => {
  if (primeContractorSelected !== null) {
    const query = `prime_contractor=${primeContractorSelected}&is_active=true&page=${page}`;
    main_api.get(adminAPIsEndPoints.LIST_CONTRACTORS(query))
      .then((response) => {
        const result = response?.data?.results;

        // Handle pagination
        if (response.data.next) {
          const urlObj = new URL(response.data.next);
          const params = new URLSearchParams(urlObj.search);
          const pageNumber = params.get('page');
          setSubContractorOptionsPage(pageNumber);
        } else {
          setSubContractorOptionsPage(null);
        }

        // Generate options array from API response
        let options = [...subContractorOptions, ...result?.map(subContractor => ({
          ...subContractor,
          label: subContractor.name.charAt(0).toUpperCase() + subContractor.name.slice(1),
          value: subContractor.id,
        }))];


        // Ensure the selected contractor is included at the beginning of the options
        if (editTruckValues?.sub_contractor) {
          const selectedContractor = {
            label: editTruckValues?.sub_contractor?.name.charAt(0).toUpperCase() + editTruckValues?.sub_contractor?.name.slice(1),
            value: editTruckValues?.sub_contractor?.id,
          };

          options = [selectedContractor, ...options.filter(item => item.value !== selectedContractor.value)];
        }

        // Filter out duplicate options and keep the one with more properties
        const uniqueOptions = options.reduce((acc, current) => {
          const existing = acc.find(item => item.value === current.value);
          if (existing) {
            // Compare the number of keys and keep the one with more information
            if (Object.keys(current).length > Object.keys(existing).length) {
              return acc.map(item => item.value === current.value ? current : item);
            }
            return acc;
          } else {
            acc.push(current);
            return acc;
          }
        }, []);


        setSubContractorOptions(uniqueOptions);
      })
      .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
  }
};


//---------------------------- Fetch Truck Types --------------------------//

const fetchTruckTypes = async (query = '', page = 1) => {
  main_api.get(`${adminAPIsEndPoints.LIST_TRUCK_TYPE(query)}&page=${page}`)
  .then((response) => {
    const result = response?.data?.results;
    let options = result.map(item => ({
      label: item?.type?.charAt(0).toUpperCase() + item?.type?.slice(1),
      value: item.id,
    }));
    
    if (editTruckValues?.type) {
      const selectedOption = options.filter(option => option.value === editTruckValues?.type).map(item => ({
        label: item.label.charAt(0).toUpperCase() + item.label.slice(1),
        value: item.id,
      }));
      
      options = [...selectedOption, ...options?.filter(item => !selectedOption.some(selected => selected.value === item.value))];
    }
    setTruckTypeOptions(options);
  }).catch((error) => {
    pushNotification(error?.response?.data?.detail, "error");
  });
};

//--------------------------- Fetch Attachments-----------------------//

const fetchAttachments = async () => {
  // API call to fetch attachments
  const objectId = editTruckValues?.id;
  if (objectId) {
      const query = `object_id=${objectId}&module_name=truck`;
      main_api.get(adminAPIsEndPoints.LIST_UPLOADED_FILES(query))
      .then((response) => {
        const result = response?.data?.results;
        let attachments = result?.map(item => ({
          key: item?.key,
          url: item?.url,
          name: item?.key.split("/").pop(),
          type: item?.type,
          attachment_type: item?.attachment_type
        }))
        setUploadedFiles(attachments);
      })
      .catch((error) => {
          pushNotification(error?.response?.data?.detail, "error");
      });
  }   
}


const generateTruckNumber = () => {
  let truckNumber = uuidv4();
  if (primeContractorPrefix !== null && primeContractorPrefix !== undefined) {
    truckNumber = primeContractorPrefix + "-" + truckNumber;
  } else if (subContractorPrefix !== null && subContractorPrefix !== undefined) {
    truckNumber = subContractorPrefix + "-" + truckNumber;
  } else {
      truckNumber = "" + truckNumber;
  }
  form.setFieldsValue({truck_number: truckNumber});
  setTruckNumber(truckNumber);
};


  useEffect(() => {
    fetchProjectOptions();
    fetchPrimeContractors();
    fetchTruckTypes();
  }, []);

  useEffect(() => {
    if (editTruckValues && editTruckValues?.id) {
      fetchAttachments();
    }
  }, [editTruckValues]);

  useEffect(() => {
    if (primeContractorSelected !== null) {
      fetchSubContractors();
    }
  }, [primeContractorSelected]);

 

  useEffect(() => {
    generateTruckNumber();
}, [primeContractorPrefix, subContractorPrefix]);
    useEffect(() => {
      if (editTruckValues) {
          form.setFieldsValue({
              project: editTruckValues?.project?.id ,
              // prime_contractor: editTruckValues?.project?.contractor ,
              sub_contractor: editTruckValues?.sub_contractor?.id,
              description: editTruckValues?.description ,
              is_leaner_hanger: editTruckValues?.is_leaner_hanger ,
              license_state: editTruckValues?.license_state ,
              license_expire_date: editTruckValues?.license_expire_date ? dayjs(editTruckValues.license_expire_date) : null,
              license_tag_number: editTruckValues?.license_tag_number ,
              side_boards: editTruckValues?.side_boards || false,
              open_back: editTruckValues?.open_back || false,
              hand_loader: editTruckValues?.hand_loader || false,
              distinguish_features: editTruckValues?.distinguish_features ,
              certification_status: editTruckValues?.certification_status,
              is_driver_the_owner: editTruckValues?.is_driver_the_owner,
              is_active: editTruckValues?.is_active || true,
              make: editTruckValues?.make ,
              model: editTruckValues?.model ,
              year: editTruckValues?.year || null,
              vin_number: editTruckValues?.vin_number ,
              color: editTruckValues?.color ,
              bed_capacity: editTruckValues?.bed_capacity || null,
              truck_number: editTruckValues?.truck_number ,
              type: editTruckValues?.type?.id ,
              truck_owner_name: editTruckValues?.truck_owner_name ,
              truck_owner_company: editTruckValues?.truck_owner_company ,
              truck_owner_city: editTruckValues?.truck_owner_city ,
              truck_owner_address: editTruckValues?.truck_owner_address ,
              truck_owner_latitude: editTruckValues?.truck_owner_latitude || null,
              truck_owner_longitude: editTruckValues?.truck_owner_longitude || null,
              truck_owner_state: editTruckValues?.truck_owner_state ,
              truck_owner_zip_code: editTruckValues?.truck_owner_zip_code ,
              truck_owner_phone_number: editTruckValues?.truck_owner_phone_number ,
              truck_driver_name: editTruckValues?.truck_driver_name ,
              truck_driver_city: editTruckValues?.truck_driver_city ,
              truck_driver_state: editTruckValues?.truck_driver_state ,
              truck_driver_address: editTruckValues?.truck_driver_address ,
              truck_driver_latitude: editTruckValues?.truck_driver_latitude || null,
              truck_driver_longitude: editTruckValues?.truck_driver_longitude || null,
              truck_driver_zip_code: editTruckValues?.truck_driver_zip_code ,
              truck_driver_phone_number: editTruckValues?.truck_driver_phone_number ,
              truck_driver_driving_license_number: editTruckValues?.truck_driver_driving_license_number ,
              truck_driver_license_state: editTruckValues?.truck_driver_license_state ,
              truck_driver_license_expire_date: editTruckValues?.truck_driver_license_expire_date ? dayjs(editTruckValues.truck_driver_license_expire_date) : null,
              truck_driver_email: editTruckValues?.truck_driver_email 
          });

          setTruckDriverAddress(editTruckValues?.truck_driver_address);
          setTruckDriverAddressLatAndLong([editTruckValues?.truck_driver_latitude, editTruckValues?.truck_driver_longitude]);
          setTruckOwnerAddress(editTruckValues?.truck_owner_address);
          setTruckOwnerAddressLatAndLong([editTruckValues?.truck_owner_latitude, editTruckValues?.truck_owner_longitude]);
      }
  }, [editTruckValues]);

  useEffect(() => {
    if (editTruckValues) {
      setPrimeContractorOptions([{
        label: editTruckValues?.project?.contractor?.name.charAt(0).toUpperCase() + editTruckValues?.project?.contractor?.name.slice(1),
        value: editTruckValues?.project?.contractor?.id,
      }]);
      
      setPrimeContractorSelected(editTruckValues?.project?.contractor?.id);
      form.setFieldsValue({prime_contractor: editTruckValues?.project?.contractor?.id});
    }
  }, [editTruckValues]);


  useEffect(() => {
    if (editTruckValues?.bed_capacity !== null || editTruckValues?.bed_capacity !== undefined) {
      form.setFieldsValue({bed_capacity: editTruckValues?.bed_capacity});
    } 
  }, [editTruckValues]);

  useEffect(() => {
    if (truckCapacity !== null || truckCapacity !== undefined) {
      form.setFieldsValue({bed_capacity: truckCapacity});
    } 
  }, [truckCapacity]);

  

  useEffect(() => {
    if(truckDriverAddressLatAndLong || truckOwnerAddressLatAndLong){

      let driverAddressLatitude = truckDriverAddressLatAndLong[0] || null;
      form.setFieldValue("truck_driver_latitude", driverAddressLatitude);

      let driverAddressLongitude = truckDriverAddressLatAndLong[1] || null;
      form.setFieldValue("truck_driver_longitude", driverAddressLongitude);

      let ownerAddressLatitude = truckOwnerAddressLatAndLong[0] || null;
      form.setFieldValue("truck_owner_latitude", ownerAddressLatitude);

      let ownerAddressLongitude = truckOwnerAddressLatAndLong[1] || null;
      form.setFieldValue("truck_owner_longitude", ownerAddressLongitude);
    }
  }, [truckDriverAddressLatAndLong, truckOwnerAddressLatAndLong]);


    const handleCertificationStatusSwitchChange = (checked) => {
      setCertificationStatus(checked);
      form.setFieldsValue({ certification_status: checked }); 
      
    };


    const handleProjectChange = (value) => {
      setProjectSelected(value);
      form.setFieldsValue({project: value});
      const primeContractor = projectOptions.filter(option => option.value === value)[0].contractor;
      if (primeContractor !== null || primeContractor !== undefined) {
        setPrimeContractorSelected(primeContractor?.id);
        setPrimeContractorOptions([{
          label: primeContractor?.name.charAt(0).toUpperCase() + primeContractor?.name.slice(1),
          value: primeContractor?.id,
        }]);
        const prefix = primeContractor?.prefix;
        setPrimeContractorPrefix(prefix);
        setSubContractorPrefix(null);
        setSubContractorOptions([]);
        setPrefixValue(prefix);
        form.setFieldsValue({prime_contractor: primeContractor?.id});
        form.setFieldsValue({sub_contractor: null});
      }

    };
    const handlePrimeContractorChange = (value) => {
      
      setPrimeContractorSelected(value);
      if (value === null || value === undefined) {
        // setPrefixValue(null);
        setPrimeContractorPrefix(null);
      }else {
        const prefix = primeContractorOptions.filter(option => option.value === value)[0].prefix;
        setPrimeContractorPrefix(prefix);
        setSubContractorPrefix(null);
      // setPrefixValue(prefix);
      }
      form.setFieldsValue({prime_contractor: value});
    };
    const handleSubContractorChange = (value) => {
      setSubContractorSelected(value);
      if (value === null || value === undefined) {
        // setPrefixValue(null);
        setSubContractorPrefix(null);
        setPrimeContractorPrefix(prefixValue);
      } else {
        const prefix = subContractorOptions.filter(option => option.value === value)[0].prefix;
        
        setSubContractorPrefix(prefix);
        setPrimeContractorPrefix(null);
        // setPrefixValue(prefix);
      }
      form.setFieldsValue({sub_contractor: value});
    };
    const handleTruckTypeChange = (value) => {
      setTruckTypeSelected(value);
      form.setFieldsValue({type: value});
    };


    const handleAttachmentsTypeChange = (value) => {
      setAttachmentType(value);
    };
   

    const handleInformationCheckboxChange = (e) => {

      setDriverIsOwner(e.target.checked);
      form.setFieldsValue({is_driver_the_owner: e.target.checked});

    };
  
    

  return (

<CustomModal  
  open={isModalOpen}
  title={title}
  width="630px"
  height="580px"
  onCancel={() => {
    setUploadedFiles([]);
    form.resetFields();
    setModalOpen(false);
  }}
  
  footer={null}
  maskClosable={false}
  // isScrollable={true}
>
<Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
  <Form name="updateTruckForm" onFinish={onFinish} form={form} layout="vertical" >
    <FormWrapper>
    <div style={{display:"flex", flexDirection:"column", marginRight:"8px", background:"#EBF0F7", borderRadius:"5px", padding:"20px"}}>
      <span style={{display:"flex", flexDirection:"row",}}>


      <FormItem 
        name="project"
        label="Project"
        rules={[
          {
            required: true,
            message: "Please Select a Project",
          },
        ]}
      >
        <PaginatedSelect fetchData={fetchProjectOptions} placeholder="Select" options={projectOptions} value={projectSelected} onChange={handleProjectChange} disabled={editTruckValues ? true : false} pageNumber={projectOptionsPage} />

      </FormItem>
      <FormItem 
        name="prime_contractor"
        label="Prime Contractor"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: true,
            message: "Please Select a Prime Contractor",
          },
        ]}
      >
        <PaginatedSelect fetchData={fetchPrimeContractors} placeholder="Select" options={primeContractorOptions} value={primeContractorSelected} disabled={true} onChange={handlePrimeContractorChange} pageNumber={primeContractorOptionsPage} />
      </FormItem>
      <FormItem 
        name="sub_contractor"
        label="Sub Contractor"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please Select a Sub Contractor",
          },
        ]}
      >
        <PaginatedSelect fetchData={fetchSubContractors} allowClear={true} placeholder="Select" options={subContractorOptions} value={subContractorSelected} disabled={editTruckValues ? true : false} onChange={handleSubContractorChange} pageNumber={subContractorOptionsPage} />
      </FormItem>
      </span>
      <FormItem
      name="truck_number"
      // label="Truck Number"
      style={{marginLeft:"5px"}}
    >
      <span style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
        <Heading text={"Truck Number:"} margin="0px 10px 0px 0px" fontSize="0.9rem" color="#3B3B3B" fontWeight={700} />
        <Heading text={editTruckValues?.truck_number || truckNumber } margin="0px 0px 0px 0px" fontSize="0.75rem" color="#3B3B3B" fontWeight={700} />
      </span>
    </FormItem> 
    </div>

    <Heading text="Driver Details" margin="20px 0px 20px 0px" />
    
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>


    <FormItem 
        name="truck_driver_name"
        label="Name"
        rules={[
          {
            required: false,
            message: "Please Enter Driver's Name",
          },
          {
            min: 3,
            max: 50,
            message: "Name should be between 3 to 50 characters",
          },
          {
            pattern: /^[a-zA-Z\s]+$/,
            message: "Name can only contain letters and spaces",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Not required, so skip validation if empty
              }
              if (value.length < 3 || value.length > 50) {
                return Promise.reject(new Error("Name should be between 3 to 50 characters"));
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />
    </FormItem>
    <FormItem 
        name="truck_driver_city"
        label="City"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please Enter City Name",
          },
          {
            min: 3,
            max: 50,
            message: "City Name should be between 3 to 50 characters",
          },
          {
            pattern: /^[a-zA-Z\s]+$/,
            message: "City Name can only contain letters and spaces",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Not required, so skip validation if empty
              }
              if (value.length < 3 || value.length > 50) {
                return Promise.reject(new Error("City Name should be between 3 to 50 characters"));
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />
      </FormItem>
      <FormItem 
        name="truck_driver_state"
        label="State"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please Enter State Name",
          },
          {
            min: 3,
            max: 50,
            message: "State Name should be between 3 to 50 characters",
          },
          {
            pattern: /^[a-zA-Z\s]+$/,
            message: "State Name can only contain letters and spaces",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Not required, so skip validation if empty
              }
              if (value.length < 3 || value.length > 50) {
                return Promise.reject(new Error("State Name should be between 3 to 50 characters"));
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />
      </FormItem>
    </div>
    <div  style={{display:"flex", flexDirection:"column"}}>

        <span style={{marginRight:"8px"}}>

          <LocationSelector
              address={truckDriverAddress}
              setAddress={setTruckDriverAddress}
              addressLatAndLong={truckDriverAddressLatAndLong}
              setAddressLatAndLong={setTruckDriverAddressLatAndLong}
              form={form}
              checked={false}
              label='Address'
              name='truck_driver_address'
            />
        </span>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem name="truck_driver_latitude" label="Latitude">
          <Input value={truckDriverAddressLatAndLong[0] || null} disabled={true} />
      </FormItem>
      <FormItem name="truck_driver_longitude" label="Longitude" style={{marginLeft:"5px"}}>
          <Input value={truckDriverAddressLatAndLong[1] || null} disabled={true} />
      </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>


    <FormItem 
        name="truck_driver_zip_code"
        label="Zip Code"
        rules={[
          {
            required: false,
            message: "Please Enter Zip Code",
          },
          {
            pattern: /^\d{3,5}(-\d{4})?$/,
            message: "Zip Code should be 3-5 digits or in the format 12345 or 12345-6789",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Not required, so skip validation if empty
              }
              if (value.length < 3 || value.length > 10) {
                return Promise.reject(new Error("Zip Code should be 3 to 5 digits, or in the format 12345-6789"));
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />
    </FormItem>
    <FormItem 
        name="truck_driver_phone_number"
        label="Telephone"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please enter your phone number",
          },
          {
            pattern: /^\+\d{10,15}$/,
            message: "Phone number must start with a + and contain 10 to 15 digits",
          },
          {
            validator: (_, value) => {
              if (!value || (value.length >= 10 && value.length <= 15)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Phone number must be between 10 and 15 characters"));
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />
      </FormItem>
      <FormItem 
        name="truck_driver_driving_license_number"
        label="Driving License #"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please Enter Driving License Number",
          },
          {
            pattern: /^[A-Za-z0-9\- ]{3,50}$/,
            message: "Driving License Number can only contain letters, digits, spaces, and dashes",
          },
          {
            validator: (_, value) => {
              if (!value || (value.length >= 3 && value.length <= 50)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Driving License Number should be between 3 to 50 characters"));
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />
      </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>


    <FormItem 
        name="truck_driver_license_state"
        label="License State"
        rules={[
          {
            required: false,
            message: "Please Enter License State",
          },
          {
            pattern: /^[A-Za-z\s\-]{3,50}$/,
            message: "License State can only contain letters, spaces, and dashes",
          },
          {
            validator: (_, value) => {
              if (!value || (value.length >= 3 && value.length <= 50)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("License State should be between 3 to 50 characters"));
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />
    </FormItem>
    <FormItem 
        name="truck_driver_license_expire_date"
        label="License Expiration Date"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please Enter License Expiration Date",
          },
          {
            type: "object",
            message: "The input is not a valid date",
          },
          {
            validator: (_, value) => {
              if (value && value.isBefore(dayjs())) {
                return Promise.reject("Expiration Date should be in the future");
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <DatePicker 
          placeholder="MM/DD/YYYY" 
          format={
            {
              format: 'MM/DD/YYYY',
              setFieldsValue: 'MM/DD/YYYY',
            }
          }
          style={{width:"230px"}} 
          />
      </FormItem>
      <FormItem 
        name="truck_driver_email"
        label="Email"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: true,
            message: "Please enter your email",
          },
          {
            type: "email",
            message: "Please enter a valid email",
          },
          {
            validator: (_, value) => {
              // Only validate if there is a value
              if (!value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Email must be in the format: example@domain.com"));
            },
          },
        ]}
      >
        <Input placeholder="Enter Here" />
      </FormItem>
    </div>

    <Heading text="Owner Details" margin="20px 0px 20px 0px" />
    <FormItem name="is_driver_the_owner" valuePropName='checked'>

    <Checkbox style={{marginLeft:"8px"}} defaultChecked={editTruckValues?.is_driver_the_owner} onChange={handleInformationCheckboxChange}>Same as Above</Checkbox>
    </FormItem>
    {!driverIsOwner && (<>
    
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem 
          name="truck_owner_name"
          label="Name"
          rules={[
            {
              required: false,
              message: "Please Enter Owner's Name",
            },
            {
              min: 3,
              max: 50,
              message: "Name should be between 3 to 50 characters",
            },
            {
              pattern: /^[a-zA-Z\s]+$/,
              message: "Name can only contain letters and spaces",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve(); // Not required, so skip validation if empty
                }
                if (value.length < 3 || value.length > 50) {
                  return Promise.reject(new Error("Name should be between 3 to 50 characters"));
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input placeholder="Enter Here" />
      </FormItem>
      <FormItem 
          name="truck_owner_company"
          label="Company"
          style={{marginLeft:"5px"}}
          rules={[
            {
              required: false,
              message: "Please Enter Company Name",
            },
            {
              pattern: /^[a-zA-Z0-9\s]+$/, // Allows letters, numbers, and spaces
              message: "Company Name can only contain letters, numbers, and spaces",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve(); // Not required, so skip validation if empty
                }
                if (value.length < 3 || value.length > 50) {
                  return Promise.reject(new Error("Company Name should be between 3 to 50 characters"));
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>
        <FormItem 
          name="truck_owner_city"
          label="City"
          style={{marginLeft:"5px"}}
          rules={[
            {
              required: false,
              message: "Please Enter City Name",
            },
            {
              min: 3,
              max: 50,
              message: "City Name should be between 3 to 50 characters",
            },
            {
              pattern: /^[a-zA-Z\s]+$/, // Allows letters and spaces
              message: "City Name can only contain letters and spaces",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve(); // Not required, so skip validation if empty
                }
                if (value.length < 3 || value.length > 50) {
                  return Promise.reject(new Error("City Name should be between 3 to 50 characters"));
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>
      </div>

      <div style={{display:"flex", flexDirection:"column"}}>

        <span style={{marginRight:"8px"}}>

          <LocationSelector
              address={truckOwnerAddress}
              setAddress={setTruckOwnerAddress}
              addressLatAndLong={truckOwnerAddressLatAndLong}
              setAddressLatAndLong={setTruckOwnerAddressLatAndLong}
              form={form}
              checked={true}
              label='Address'
              name='truck_owner_address'
            />
        </span>
      </div>
      <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem name="truck_owner_latitude" label="Latitude">
          <Input value={truckOwnerAddressLatAndLong[0] || null} disabled={true} />
      </FormItem>
      <FormItem name="truck_owner_longitude" label="Longitude" style={{marginLeft:"5px"}}>
          <Input value={truckOwnerAddressLatAndLong[1] || null} disabled={true} />
      </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem 
        name="truck_owner_state" 
        label="State"
        rules={[
          {
            required: false,
            message: "Please Enter State Name",
          },
          {
            min: 3,
            max: 50,
            message: "State Name should be between 3 to 50 characters",
          },
          {
            pattern: /^[a-zA-Z\s]+$/, // Allows letters and spaces
            message: "State Name can only contain letters and spaces",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Not required, so skip validation if empty
              }
              if (value.length < 3 || value.length > 50) {
                return Promise.reject(new Error("State Name should be between 3 to 50 characters"));
              }
              return Promise.resolve();
            },
          }
        ]}
      >
        <Input placeholder="Enter Here" />

      </FormItem>
      <FormItem 
        name="truck_owner_zip_code" 
        label="Zip Code"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please Enter Zip Code",
          },
          {
            pattern: /^\d{3,5}(-\d{4})?$/,
            message: "Zip Code should be 3-5 digits or in the format 12345 or 12345-6789",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve(); // Not required, so skip validation if empty
              }
              if (value.length < 3 || value.length > 10) {
                return Promise.reject(new Error("Zip Code should be 3 to 5 digits, or in the format 12345-6789"));
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />

      </FormItem>
    </div>
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem 
        name="truck_owner_email" 
        label="Email"
        rules={[
          {
            required: false,
            message: "Please enter your email",
          },
          {
            type: "email",
            message: "Please enter a valid email",
          },
          {
            validator: (_, value) => {
              // Only validate if there is a value
              if (!value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Email must be in the format: example@domain.com"));
            },
          },
        ]}
      >
        <Input placeholder="Enter Here" />

      </FormItem>
      <FormItem 
        name="truck_owner_phone_number" 
        label="Telephone"
        style={{marginLeft:"5px"}}
        rules={[
          {
            required: false,
            message: "Please enter your phone number",
          },
          {
            pattern: /^\+\d{10,15}$/,
            message: "Phone number must start with a + and contain 10 to 15 digits",
          },
          {
            validator: (_, value) => {
              if (!value || (value.length >= 10 && value.length <= 15)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Phone number must be between 10 and 15 characters"));
            }
          }
        ]}
      >
        <Input placeholder="Enter Here" />

      </FormItem>
    </div>
    </>)}

    <Heading text="Truck Information" margin="20px 0px 20px 0px" />
   
    <FormItem
      name="type"
      label="Truck Type"
      style={{width:"580px"}}
      rules={[
        {
          required: false,
          message: "Please Select Truck Type",
        },
      ]}
    >
      <PaginatedSelect fetchData={fetchTruckTypes} placeholder="Select" options={truckTypeOptions} value={truckTypeSelected} onChange={handleTruckTypeChange} />
    </FormItem>
    
        
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem 
          name="make"
          label="Make"
          rules={[
            {
              required: true,
              message: "Please Enter Make",
            },
            {
              pattern: /^[a-zA-Z\s]+$/,
              message: "Make can only contain letters and spaces",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(new Error("Make is required")); // Ensure value is not empty
                }
                if (value.length < 3 || value.length > 50) {
                  return Promise.reject(new Error("Make should be between 3 to 50 characters"));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder="Enter Here" />
      </FormItem>
      <FormItem 
          name="model"
          label="Model"
          style={{marginLeft:"5px"}}
          rules={[
            {
              required: true,
              message: "Please Enter Model",
            },
            {
              pattern: /^[a-zA-Z0-9\s]+$/,
              message: "Model can only contain letters, numbers, and spaces",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(new Error("Model is required")); // Ensure value is not empty
                }
                if (value.length < 3 || value.length > 50) {
                  return Promise.reject(new Error("Model should be between 3 to 50 characters"));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>
        <FormItem 
          name="year"
          label="Year"
          style={{marginLeft:"5px"}}
          rules={[
            {
                required: true,
                message: "Please enter the year", // Required field message
            },
            {
                pattern: /^\d{4}$/, // Regex for a 4-digit number
                message: "Year should be a 4-digit number (e.g., 2020).", // Error message for pattern mismatch
            },
            {
                validator: (_, value) => {
                    if (!value) {
                        return Promise.reject(new Error("Year is required.")); // Reject if empty
                    }
                    if (value < 1900 || value > new Date().getFullYear()) {
                        return Promise.reject(new Error("Year must be between 1900 and the current year.")); // Reject if not in range
                    }
                    return Promise.resolve(); // Accept valid year
                }
            }
        ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>

    </div>

    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem
        name="vin_number"
        label="VIN Number"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const initialVinNumber = editTruckValues?.vin_number;

              if (initialVinNumber === value) {
                // No need to validate if the value hasn't changed
                return Promise.resolve();
              }

              // Perform validation only if value is different
              if (!value || /^[A-HJ-NPR-Z0-9-]{3,20}$/.test(value)) {
                return Promise.resolve();
              }

              return Promise.reject(new Error("VIN Number must be between 3 to 20 characters and only contain valid characters (excluding I, O, Q)."));
            },
          }),
        ]}
      >
        <Input placeholder="Enter Here" />
      </FormItem>

      <FormItem 
          name="color"
          label="Color"
          style={{marginLeft:"5px"}}
          rules={[
            {
                required: false, // Field is optional
                message: "Please enter a color",
            },
            {
                min: 3,
                max: 50,
                message: "Color should be between 3 to 50 characters",
            },
            {
                pattern: /^[a-zA-Z\s]+$/, // Allows only letters and spaces
                message: "Color can only contain letters and spaces",
            },
            {
                validator: (_, value) => {
                    if (!value) {
                        return Promise.resolve(); // Not required, so skip validation if empty
                    }
                    if (value.length < 3 || value.length > 50) {
                        return Promise.reject(new Error("Color should be between 3 to 50 characters"));
                    }
                    return Promise.resolve();
                }
            }
        ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>
        
    </div>
    <FormItem 
          name="bed_capacity"
          label="Bed Capacity"
          style={{ width:"590px"}}
          rules={[
            {
                required: false, // Field is optional
                message: "Please enter bed capacity",
            },
            {
                pattern: /^(\d+(\.\d+)?)?$/, // Allows float numbers (e.g., 10, 10.5)
                message: "Bed capacity must be a valid number",
            },
            {
                validator: (_, value) => {
                    if (!value) {
                        return Promise.resolve(); // Not required, so skip validation if empty
                    }
                    if (parseFloat(value) <= 0) {
                        return Promise.reject(new Error("Bed capacity must be a positive number"));
                    }
                    return Promise.resolve();
                }
            }
        ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>
    <Heading text="Don't know Capacity?" margin="20px 0px 10px 0px" />
    <Button onClick={() => setCalculateCapacityModal(true)} style={{ marginBottom:"10px", height:"32px", width:"82px", borderRadius:"8px", background:"#3669AE", color:"white"}}>Calculate</Button>

    <FormItem 
      name="description" 
      label="Description" 
      style={{width:"590px"}}
      rules={[
        {
            required: false, // Field is optional
            message: "Please enter a description",
        },
        {
            max: 300, // Max character limit for the description
            message: "Description should not exceed 500 characters",
        },
        {
            pattern: /^[\s\S]*$/, // Allows any characters, including new lines
            message: "Description can include any characters",
        },
        {
            validator: (_, value) => {
                const sanitizedValue = value ? value.replace(/[<>]/g, '') : ''; // Sanitize input to prevent XSS
                if (!value) {
                    return Promise.resolve(); // Not required, so skip validation if empty
                }
                if (sanitizedValue.length > 300) {
                    return Promise.reject(new Error("Description should not exceed 300 characters"));
                }
                return Promise.resolve();
            }
        }
    ]}
    >
      <TextArea placeholder='Enter any additional notes' />
    </FormItem>
    
    <FormItem
      name='is_leaner_hanger'
      label='Leaner / Hanger'
    >
      <Radio.Group style={{display:"flex", flexDirection:"row"}}>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </FormItem>

    <Heading text="License Plate Information" margin="20px 0px 20px 0px" />
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem 
          name="license_state"
          label="State"
          rules={[
            {
              required: false, // Field is optional
              message: "Please Enter State",
            },
            {
              min: 3,
              max: 50,
              message: "State Should be between 3 to 50 Characters",
            },
            {
              pattern: /^[a-zA-Z\s]+$/, // Regex to allow only letters and spaces
              message: "State can only contain letters and spaces",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve(); // Not required, so skip validation if empty
                }
                if (value.length < 3 || value.length > 50) {
                  return Promise.reject(new Error("State should be between 3 to 50 characters"));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder="Enter Here" />
      </FormItem>
      <FormItem 
          name="license_tag_number"
          label="Tag Number"
          style={{marginLeft:"5px"}}
          rules={[
            {
              required: false, // Field is optional
              message: "Please Enter Tag Number",
            },
            {
              pattern: /^[a-zA-Z0-9\-]+$/, // Regex to allow alphanumeric characters and dashes
              message: "Tag Number can only contain letters, numbers, and dashes",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve(); // Not required, so skip validation if empty
                }
                if (value.length < 3 || value.length > 50) {
                  return Promise.reject(new Error("Tag Number should be between 3 to 50 characters"));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input placeholder="Enter Here" />
        </FormItem>
        <FormItem 
          name="license_expire_date"
          label="Expiration Date"
          style={{marginLeft:"5px"}}
          rules={[
            {
              type: "object",
              message: "The input is not a valid date",
            },
            {
              required: false,
              message: "Please Enter Expiration Date",
            },
            {
              validator: (_, value) => {
                if (value && value.isBefore(dayjs())) {
                  return Promise.reject("Expiration Date should be in the future");
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <DatePicker 
            placeholder="MM/DD/YYYY" 
            format={
              {
                format: 'MM/DD/YYYY',
                setFieldsValue: 'MM/DD/YYYY',
              }
            }
            style={{width:"192px"}}
          />
        </FormItem>
    </div>

    <Heading text="Features" margin="20px 0px 20px 0px" />
    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem
        name='side_boards'
        valuePropName='checked'
      >
        <Checkbox>Side Boards</Checkbox>
      </FormItem>
      <FormItem
        name='open_back'
        valuePropName='checked'
        style={{marginLeft:"5px"}}
      >
        <Checkbox>Open Back</Checkbox>
      </FormItem>
      <FormItem
        name='hand_loader'
        valuePropName='checked'
        style={{marginLeft:"5px"}}
      >
       <Checkbox>Hand Loader</Checkbox>
      </FormItem>
    </div>
    <FormItem
      name='distinguish_features'
      label='Distinguish Features'
      style={{width:"590px"}}
      rules={[
        {
          required: false, // Field is optional
        },
        {
          max: 100, // Maximum character limit
          message: "Distinguishing features should not exceed 100 characters",
        },
        {
          validator: (_, value) => {
            if (value && value.length > 100) {
              return Promise.reject(new Error("Distinguishing features should not exceed 100 characters"));
            }
            return Promise.resolve();
          }
        }
      ]}
    >
      <TextArea placeholder='Mention Distinguishing Features' />
    </FormItem>
    <FormItem name="certification_status" label="Certification Status">
      <CustomSwitch defaultChecked={certificationStatus} onChange={handleCertificationStatusSwitchChange} />
    </FormItem>

    <FormItem name="attachments" label="Attachments" style={{width:"590px"}}>
    <CustomUploader
      file={file}
      setFile={setFile}
      uploadedFiles={uploadedFiles} 
      setUploadedFiles={setUploadedFiles} 
      directory={"truck"} 
    />
    </FormItem>

      </FormWrapper>
      <Divider/>
    <SaveContainer className="d-flex justify-content-end">
      <CustomButton
        btnText={"Cancel"}
        margin="0px 5px"
        noBackground
        hideIcon={true}
        onClick={() => setModalOpen(false)}
      />
      <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
    </SaveContainer>
  </Form>
  {
    calculateCapacityModal && (
      <TruckCapacity
        isModalOpen={calculateCapacityModal}
        setModalOpen={setCalculateCapacityModal}
        title={"Calculate Truck Capacity"}
        truckCapacity={truckCapacity}
        setTruckCapacity={setTruckCapacity}
      />
    )
  }
</CustomModal>



  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const FormWrapper = styled.div`
  height: 585px;
  width:  600px;
  overflow: auto;

 /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;














