import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';

const { Option } = Select;

export default function PaginatedSelect({fetchData, placeholder = "Select", mode = undefined, options, value, allowClear = false, onClear= () => {}, disabled = false, onChange, style, pageNumber, maxTagCount}) {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        fetchData(page);
      }, [page]);

    const handlePopupScroll = (event) => {
        const { target } = event;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight && !loading && hasMore) {
          
          if(pageNumber !== null && pageNumber !== undefined){
            setPage(pageNumber);
          } 
        }
      };

   
      
  return (
    <Select
      mode={mode}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      allowClear={allowClear}
      onClear={onClear}
      disabled={disabled}
      style={style}
      maxTagCount={maxTagCount}
      onPopupScroll={handlePopupScroll}
      notFoundContent={!options?.length && !loading ? "No Data" : loading ? <Spin size="small" /> : null}
    />
  );
}
