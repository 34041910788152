import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, Input, Divider, DatePicker, Select, Button } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import SearchInput from '../../components/searchInput/SearchInput';
import HeadingComponent from '../../components/headingComponent/heading';
import { AntdesignTable } from '../../components/antDesignTable/AntdesignTable';
import { ReactComponent as NotesBadge } from "../../assets/rawSvg/notesBadge.svg";
import { ReactComponent as TicketsBadge } from "../../assets/rawSvg/ticketBadge.svg";
import { ReactComponent as UsersBadge } from "../../assets/rawSvg/usersBadge.svg";
import moment from 'moment';
import { dashboardProjectsColumns, dashboardTableColumns, manageProjectsColumns } from '../../util/antdTableColumns';
import { useSelector } from 'react-redux';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import { pushNotification } from '../../util/notification';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { main_api } from '../../api/axiosHelper';
import PaginatedSelect from '../../components/customSelect/paginatedSelect';
import Maps from '../map/maps';
import { cyDataObj, roadwayMilesObj } from './tableObject';
import ReactSpeedometer from "react-d3-speedometer"
import CustomFilter from '../../components/customFilterWithSearchBar/customFilter';
import CustomButton from '../../components/customButton/customButton';
import CustomMap from '../map/customMap';

const ordinals = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth',
};

export default function Dashboard() {
 
  
  
  const [permissionText, setPermissionText] = useState('');
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  const [projectsDataForTable, setProjectsDataForTable] = useState({
    data: [],
    count: 0,
  });

  const [address, setAddress] = useState(null);
  const [addressLatAndLong, setAddressLatAndLong] = useState([{latitude: null, longitude: null}]);

  const [eventOptions, setEventOptions] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [eventOptionsPage, setEventOptionsPage] = useState(1);

  const [clientOptions, setClientOptions] = useState([]);
  const [clientSelected, setClientSelected] = useState(null);
  const [clientOptionsPage, setClientOptionsPage] = useState(1);

  const [projectOptions, setProjectOptions] = useState([]);
  const [projectSelected, setProjectSelected] = useState(null);
  const [projectOptionsPage, setProjectOptionsPage] = useState(1);
  const [pivotOption, setPivotOption] = useState(null);
  const [currentSelect, setCurrentSelect] = useState(null);


  const profileState = useSelector((state) => state?.profileData?.profileData);
  const [tableColumns, setTableColumns] = useState({});
  const [statsCoverage, setStatsCoverage] = useState({});
  


  const defaultUsername = 'Guest';

  localStorage.setItem('username', `${profileState?.name ?? defaultUsername}`);
  const currentUser = localStorage.getItem('username');

  const greetings = `Welcome ${currentUser}`;

  const speedometerValue = statsCoverage?.total_estimated_cy && 
                           (statsCoverage?.cy_removed_to_date / statsCoverage?.total_estimated_cy)*100;


  const fetchTicketLatLng = async () => {
    main_api.post(adminAPIsEndPoints.DASHBOARD_TICKET_LATLNG, {
      event_ids: eventSelected,
      client_ids: clientSelected,
      project_ids: projectSelected,
    })
      .then(({data}) => {
        const latlng = data.result
        .map(item => item.addresses.map(address => ({ latitude: address.latitude, longitude: address.longitude, shape_name: item.shape_name, debris_color: item.debris_color }))
        .filter(coord => coord.latitude !== null && coord.latitude !== undefined && 
                         coord.longitude !== null && coord.longitude !== undefined))
        .flat();
        
        setAddressLatAndLong(latlng);        
      })
      .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
  }

  const fetchStatsCoverage = async () => {
    main_api.post(adminAPIsEndPoints.DASHBOARD_STATS_COVERAGE, {
      event_ids: eventSelected,
      client_ids: clientSelected,
      project_ids: projectSelected,
    })
      .then(({data}) => {
        setStatsCoverage(data.result);        
      })
      .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
  }

  const fetchDebrisStats = async () => {
    main_api.post(adminAPIsEndPoints.DASHBOARD_DEBRIS_STATS, {
      event_ids: eventSelected,
      client_ids: clientSelected,
      project_ids: projectSelected,  
    })
      .then(({data}) => {
        formatTableData(data.results);
      })
      .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
  }

  const formatTableData = (results) => {
    const data = {
      subactivity: [],
      debris: [],
      values: {},
    };
  
    results.forEach((item) => {
      const debrisKey = `${item.debris_type__name} ${item.unit_of_measure ? `(${item.unit_of_measure})` : ""}`;
      if (!data.subactivity.includes(item.sub_activity__name)) {
        data.subactivity.push(item.sub_activity__name);
      }
      if (!data.debris.includes(debrisKey)) {
        data.debris.push(debrisKey);
      }
      if (!data.values[item.sub_activity__name]) {
        data.values[item.sub_activity__name] = {};
      }
  
      // Treat zero as an empty value
      const value = item.total_value === 0 ? "" : item.total_value;
      data.values[item.sub_activity__name][debrisKey] = value;
    });
  
    // Build table data
    let tableData = data.subactivity.map((sub, index) => {
      const row = { key: index, subactivity: sub };
      let allZeros = true;
  
      data.debris.forEach((debris) => {
        const value = data.values[sub][debris] ?? "";
        row[debris] = value;
  
        // Check if the entire row has all zeros or empty values
        if (value !== "" && value !== 0) {
          allZeros = false;
        }
      });
  
      // Exclude rows where all values are zero or empty
      return allZeros ? null : row;
    }).filter(Boolean);
  
    if (tableData.length === 0) {
      // If no rows, clear columns and tableData
      setTableColumns({ columns: [], tableData: [] });
      return;
    }
  
    // Calculate column totals
    const totals = { key: 'total', subactivity: 'Total' };
    let allColumnsZero = {};
  
    data.debris.forEach((debris) => {
      let total = tableData.reduce((sum, row) => sum + (row[debris] || 0), 0);
      total = total === 0 ? "" : total; // Treat zero as an empty value
      totals[debris] = total;
  
      // Keep track of columns that have only zero or empty values
      allColumnsZero[debris] = total === "" || total === 0;
    });
  
    // Add the totals row to the table data
    tableData.push(totals);
  
    // Remove columns where all values (including the total) are zero or empty
    const columns = [
      { title: 'Subactivity', dataIndex: 'subactivity', key: 'subactivity' },
      ...data.debris.map((debris) => {
        if (allColumnsZero[debris]) return null;
        return {
          title: debris,
          dataIndex: debris,
          key: debris,
          render: (value) => (value !== "" ? value : ""), // Render empty strings as is
        };
      }).filter(Boolean),
    ];
  
    setTableColumns({ columns, tableData });
  };
    


//--------------------------- Fetch Events Options ---------------------------//
    
const fetchEventOptions = async (page = 1, reset) => {
  let query = `is_active=true&page${page}`;
  let payload = {};

  if(pivotOption !== "event") {
    clientSelected && (payload.client_ids = clientSelected);
    projectSelected && (payload.project_ids = projectSelected);
  }

  main_api.post(adminAPIsEndPoints.DASHBOARD_EVENT(query), payload)
  .then((response) => {
    const result = response.data.results;
    const newOptions = result?.map(event => ({
      ...event,
      label: event.name.charAt(0).toUpperCase() + event.name.slice(1),
      value: event.id,
    }));
    if (response.data.next) {
      const urlObj = new URL(response.data.next);
      const params = new URLSearchParams(urlObj.search);
      const pageNumber = params.get('page');
      setEventOptionsPage(pageNumber);
    } else {
      setEventOptionsPage(null);
    }
    let options = [...eventOptions, ...newOptions];

    const uniqueOptions = options.reduce((acc, current) => {
      const existing = acc.find(item => item.value === current.value);
      if (existing) {
        // Compare the number of keys and keep the one with more information
        if (Object.keys(current).length > Object.keys(existing).length) {
          return acc.map(item => item.value === current.value ? current : item);
        }
        return acc;
      } else {
        acc.push(current);
        return acc;
      }
    }, []);
    
    setEventOptions(response.data.count > 10 ? uniqueOptions : newOptions);
  }).catch((error) => {
    pushNotification(error?.response?.data?.detail, "error");
  });
};

//--------------------- Fetch Clients Options ---------------------//

const fetchClientOptions = async (page = 1, reset) => {
  let query = `is_active=true&page=${page}`;
  let payload = {};

  if(pivotOption !== "client") {
    eventSelected && (payload.event_ids = eventSelected);
    projectSelected && (payload.project_ids = projectSelected);
  }
  
  main_api.post(adminAPIsEndPoints.DASHBOARD_CLIENTS(query), payload)
  .then((response) => {
      const result = response.data.results;
      const newOptions = result?.map(client => ({
        ...client,
        label: client.name.charAt(0).toUpperCase() + client.name.slice(1),
        value: client.id,
      }));
     
      if (response.data.next) {
        const urlObj = new URL(response.data.next);
        const params = new URLSearchParams(urlObj.search);
        const pageNumber = params.get('page');
        setClientOptionsPage(pageNumber);
      } else {
        setClientOptionsPage(null);
      }
      
      let options = [...clientOptions, ...newOptions];

    
      const uniqueOptions = options.reduce((acc, current) => {
        const existing = acc.find(item => item.value === current.value);
        if (existing) {
          // Compare the number of keys and keep the one with more information
          if (Object.keys(current).length > Object.keys(existing).length) {
            return acc.map(item => item.value === current.value ? current : item);
          }
          return acc;
        } else {
          acc.push(current);
          return acc;
        }
      }, []);

      setClientOptions(response.data.count > 10 ? uniqueOptions : newOptions);
  })
  .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
  });
};

//--------------------- Fetch Projects Options ---------------------//

const fetchProjectOptions = async (page = 1, reset) => {
  let query = `is_active=true&page=${page}`;
  let payload = {};

  if(pivotOption !== "project") {
    eventSelected && (payload.event_ids = eventSelected);
    clientSelected && (payload.client_ids = clientSelected);
  }

  main_api.post(adminAPIsEndPoints.DASHBOARD_PROJECT(query), payload)
    .then((response) => {
      const result = response?.data?.results;
      const newOptions = result?.map(project => ({
        ...project,
        label: project.name.charAt(0).toUpperCase() + project.name.slice(1),
        value: project.id,
      }));

      // Handle pagination
      if (response.data.next) {
        const urlObj = new URL(response.data.next);
        const params = new URLSearchParams(urlObj.search);
        const pageNumber = params.get('page');
        setProjectOptionsPage(pageNumber);
      } else {
        setProjectOptionsPage(null);
      }

       // Generate options array from API response
       let options = [...projectOptions, ...newOptions];


      // Filter out duplicate options and keep the one with more properties
      const uniqueOptions = options.reduce((acc, current) => {
        const existing = acc.find(item => item.value === current.value);
        if (existing) {
          // Compare the number of keys and keep the one with more information
          if (Object.keys(current).length > Object.keys(existing).length) {
            return acc.map(item => item.value === current.value ? current : item);
          }
          return acc;
        } else {
          acc.push(current);
          return acc;
        }
      }, []);


      setProjectOptions(response.data.count > 10 ? uniqueOptions: newOptions);
    })
    .catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
};

  //--------------------- Use Effect ---------------------//

  useEffect(() => {

    fetchEventOptions(1, true);
    fetchClientOptions(1, true);
    fetchProjectOptions(1, true);

    if(!eventSelected?.length && 
       !clientSelected?.length && 
       !projectSelected?.length) {
      setPivotOption(null);
    }
  }, [pivotOption, eventSelected, clientSelected, projectSelected]);


  //--------------------- Event Handlers ---------------------//

  const handleEventChange = (value) => {
    const filteredValue = value.filter(item => item != "all_selected");

    setEventSelected(filteredValue);

    if(!clientSelected?.length && !projectSelected?.length) {
      setPivotOption("event");
    }
    setCurrentSelect("event");
  }

  const handleClientChange = (value) => {
    const filteredValue = value.filter(item => item != "all_selected");

    setClientSelected(filteredValue);

    if(!eventSelected?.length && !projectSelected?.length) {
      setPivotOption("client");
    }
    setCurrentSelect("client");
  }

  const handleProjectChange = (value) => {
    const filteredValue = value.filter(item => item != "all_selected");
    
    setProjectSelected(filteredValue);

    if(!eventSelected?.length && !clientSelected?.length) {
      setPivotOption("project");
    }
    setCurrentSelect("project");
  }

  // const handleReverseGeocode = async (lat, lng) => {
  //   try {
  //     const results = await locator.locationToAddress(geocodeUrl, {
  //       location: {
  //         x: lng,
  //         y: lat,
  //       },
  //     });
  
  //     if (results.address) {
  //       const address = results.address;
  //       console.log("Reverse Geocoding Result:", address);
  //       setAddress(address); // Assuming you have a function or state to store the address details
  //       setAddressLatAndLong([lat, lng]);
  //     } else {
  //       console.log("No address found for the given coordinates.");
  //     }
  //   } catch (error) {
  //     console.error("Error in reverse geocoding:", error);
  //   }
  // };

  const addAllSelectedOption = (options, condition, pivotInstance) => {
    if (pivotOption && pivotOption == pivotInstance) {
      return options;
    }

    const allSelectedOption = {
      label: (
        <span className='d-flex flex-row-reverse justify-content-between'>
          <input
            type="checkbox"
            checked={condition} // Conditionally check the box
            readOnly
            style={{ marginRight: 8 }}
            disabled
          />
          All Selected
        </span>
      ),
      value: 'all_selected',
      disabled: true
    };
    return [allSelectedOption, ...options];
  };
  
  function checkAllSelectedCondition(pivotInstance, selectedOptions) {
    return pivotOption !== pivotInstance && pivotOption !== null && !selectedOptions?.length;
  }
  
  return (
    <div style={{ marginTop: '20px' ,marginLeft:"20px"}}>
      <div style={{ marginBlock: "8px" }}>
        <Heading text={greetings} fontSize='1.4rem'/>
        <Heading text="Here's what happening" color='lightgrey' fontSize='1.1rem'/>
      </div>
    <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', padding:"2px", marginTop:"20px"}}>
    <CardWrapper>

    <div className='d-flex align-items-center'>
      <div className='gap-2' style={{ display:"flex", flexDirection:"row",marginBlock: "10px", flexGrow: 1 }}>
        <div className='d-flex flex-grow-1 flex-column position-relative' style={{flexBasis: "33%"}}>
          <PaginatedSelect 
            fetchData={fetchEventOptions} 
            placeholder="Select Event" 
            options={addAllSelectedOption(eventOptions, checkAllSelectedCondition("event", eventSelected), "event")} 
            onChange={handleEventChange}
            pageNumber={eventOptionsPage}
            mode={"multiple"} 
            maxTagCount={"responsive"}
            style={{width:"100%", marginTop: "10px"}} 
            value={eventSelected}
          />
        </div>
        <div className='d-flex flex-grow-1 flex-column position-relative' style={{flexBasis: "33%"}}> 
          <PaginatedSelect
            fetchData={fetchClientOptions} 
            placeholder="Select Client" 
            options={addAllSelectedOption(clientOptions, checkAllSelectedCondition("client", clientSelected), "client")} 
            onChange={handleClientChange} 
            pageNumber={clientOptionsPage}
            mode={"multiple"} 
            maxTagCount={"responsive"}
            style={{width:"100%", marginTop: "10px"}}
            value={clientSelected}
          />
        </div>
        <div className='d-flex flex-grow-1 flex-column position-relative' style={{flexBasis: "33%"}}> 
          <PaginatedSelect
            fetchData={fetchProjectOptions} 
            placeholder="Select Project" 
            options={addAllSelectedOption(projectOptions, checkAllSelectedCondition("project", projectSelected), "project")} 
            onChange={handleProjectChange}
            pageNumber={projectOptionsPage} 
            mode={"multiple"} 
            maxTagCount={"responsive"}
            style={{width:"100%", marginTop: "10px"}}
            value={projectSelected}
          />
        </div>
      </div>
      <Button type="link" className='text-danger' onClick={() => {
        setEventSelected(null);
        setClientSelected(null);
        setProjectSelected(null);
        setPivotOption(null);
        setCurrentSelect(null);
        setTableColumns({});
        setAddressLatAndLong([{latitude: null, longitude: null}]);
        setStatsCoverage({});
      }}>Reset Filter</Button>
      <Button type='primary' className='me-2' onClick={() => {
        fetchDebrisStats();
        fetchStatsCoverage();
        fetchTicketLatLng();
      }
      }>Apply Filter</Button>

    </div>
    <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', padding:"2px" }}>
      <div style={{ marginBottom: '22px', position: 'relative', top: 'auto', bottom: 0, padding:"10px", overflow: "auto" }}>
        <Table 
          columns={tableColumns.columns}
          dataSource={tableColumns.tableData}
          pagination={false}
        />
      </div>
    </Card>

    <div style={{ display:"flex", flexDirection:"row", justifyContent:"space-evenly",marginBlock: "10px" }}>
      <span style={{width:"300px", height:"800px"}}>
        <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', padding:"2px" }}>
          {/* <Heading text="Project Details" fontSize='1.4rem'/> */}
          <div className='text-center'>
            <p className='text-nowrap'>Total Roadway</p>
            <p>Miles</p>
            <h1 className='fw-bold'>{statsCoverage?.total_roadway_miles ?? "--"}</h1>
            {
             statsCoverage?.pass_number?.map((pass, index) => {

              const passPercentage = (pass.pass_number && pass.total_value) ? (pass.pass_number / pass.total_value)*100 : 0;

              return <div key={index}>
                <p className='m-0'>{ordinals[pass.pass_number]} Pass</p>
                <p className='m-0'>Complete</p>
                <h2 className='fw-bold'>{pass.total_value}</h2>
                <h4 className='fw-bold mb-3'>{passPercentage}%</h4>
              </div>
            }) 
            }
          </div>
        </Card>
      </span>

      <span className='mx-2'  style={{width:"100%", height:"60%"}}>
       {/* <Maps addressLatAndLong={addressLatAndLong} handleReverseGeocode={() => {}}/> */}
        <CustomMap addressLatAndLong={addressLatAndLong} handleReverseGeocode={() => {}} draggable={false}/>
      </span>

      <span style={{width: "300px"}}>
        <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', padding:"2px" }}>
          {/* <Heading text="Project Details" fontSize='1.4rem'/> */}
          <div className='text-center'>
            <p className='text-nowrap mb-0'>Total</p>
            <p>Estimated CY</p>
            <h1 className='fw-bold'>{statsCoverage?.total_estimated_cy ?? "--"}</h1>
            <p className='text-nowrap mb-0'>CY Removed</p>
            <p>To-Date</p>
            <h1 className='fw-bold mb-4'>{statsCoverage?.cy_removed_to_date ? parseFloat(statsCoverage?.cy_removed_to_date?.toFixed(3)) : "--"}</h1>
            
            {statsCoverage?.total_estimated_cy ? <ReactSpeedometer
              valueFormat='d'
              width={200}
              ringWidth={20}
              needleHeightRatio={0.7}
              maxSegmentLabels={5}
              segments={statsCoverage?.total_estimated_cy}
              minValue={0}
              maxValue={statsCoverage?.total_estimated_cy}
              value={speedometerValue}
              textColor="#AAA"
            />:""}
        </div>
        </Card>
      </span>
    </div>
    </CardWrapper>

    </Card>
     

  </div>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};



const MapContainer = styled.div`
  width: 100%;
`;


const CardWrapper = styled.div`
  height: calc(100vh - 60px);
  max-height: 645px;
  width:  100%;
  overflow: auto;

 /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;