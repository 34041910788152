
import React, { useEffect, useState } from "react";
import { Layout, Menu, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { privateSidebarNavData, publicSidebarNavData } from "../../../constants/sidebarNavData/SidebarNavData";
import { ReactComponent as CompanyBrandIcon } from "../../../assets/rawSvg/sidebarNavIcons/logoSmall.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/rawSvg/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/rawSvg/arrowRight.svg";
import './sidebar.css';
import { flattenMenuItems, generateMenuItems } from "../../../util/generateMenuItems";
import usePermissions from "../../hooks/usePermissions";
import { useSelector } from "react-redux";
const { Sider } = Layout;




export default function Sidebar({ setIsExpanded, setPermissionPopUp,setPermissionText}) {

  //----------------------- Custom Hooks -----------------------//

  const { assignedPermissions } = usePermissions();

  const profileState = useSelector((state) => state?.profileData?.profileData);
  const assignedPermissionsArray = (assignedPermissions.assigned_permissions || []).map(perm => perm.codename)
  const sidebarNavData = profileState?.role?.is_private === true ? privateSidebarNavData : publicSidebarNavData;

  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const items = generateMenuItems(sidebarNavData);
  const flatItems = flattenMenuItems(items);
  const initialSelectedKey = flatItems.find(item => item.navigateTo === location.pathname)?.key;
  const [selectedKey, setSelectedKey] = useState(initialSelectedKey);
 

  const permissionMapping = {
    'Dashboard': ['view_debristype'],
    'Administration': [
      'view_debristype',
      'view_trucktype',
      'view_subactivity',
      'manage_hazard_type', 
      'manage_hazard_name', 
      'view_hazardname',
      'manage_role',
      'view_position',
      'menu_setup'
    ],
    'Event Management': ['view_event'],
    'Project Management': [
      'view_project', 
    ],
    'Manage Project': ['view_project'],
    'Map User': ['manage_map_user'],
    'User Management': ['view_user'],
    'Disposal Site': ['view_disposalsite'], 
    'Ticket Management': ['view_ticket'],
    'Manage Contractors': ['view_contractor_user'],
    'Manage Rate Matrix': ['view_contractorratematrix'],
    'Truck Management': ['view_truck'], 
    // 'Map': ['view_map'], 
  };


  function hasRequiredPermissions(componentName, assignedPermissionsArray) {
    const requiredPermissions = permissionMapping[componentName];
  
    if (!requiredPermissions) {
      console.warn(`No permissions mapped for component: ${componentName}`);
      return false;
    }
  
    if (Array.isArray(requiredPermissions)) {
      // Check if every required permission is in the assignedPermissionsArray
      return requiredPermissions.some(permission => assignedPermissionsArray.includes(permission));
    } else {
      // Handle the case where a single permission string is provided instead of an array
      return assignedPermissionsArray.includes(requiredPermissions);
    }
  }
  const handleMenuClick = (e) => {
    const selectedItem = flatItems.find(item => item.key === e.key );
    if (selectedItem?.navigateTo === "/reports") {
      navigate(selectedItem.navigateTo);
    } else {

      if (selectedItem && selectedItem.navigateTo && hasRequiredPermissions(selectedItem.label, assignedPermissionsArray)) {
        navigate(selectedItem.navigateTo);
      } else {
        setPermissionText(`You don't have permission to access ${selectedItem.label}`);
        setPermissionPopUp(true);
      }
    }
  };

  useEffect(() => {
    
    const selectedItem = flatItems.find(item => item.navigateTo === location.pathname);
    
    setSelectedKey(selectedItem?.key);
  }, [location.pathname, flatItems]);


  const toggleCollapse = () => {
    setIsExpanded(collapsed);
    setCollapsed(!collapsed);
  };

  return (

    <Sider width={240} collapsible collapsed={collapsed} trigger={null} onCollapse={toggleCollapse}>
      <div style={{ display: "flex", flexDirection:"column", alignItems: "center", padding: 20 , borderRight:" 1px solid #e4eaf0"}}>
        <CompanyBrandIcon width={100}/>
        <div
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            color: "#000",
            zIndex: "200",
          }}
          onClick={toggleCollapse}
        >
          {collapsed ? 
          <ArrowRightIcon 
            style={{
              position: "relative",
              bottom: "55px",
              left: "33px",
            }}
          /> 
          : 
          <ArrowLeftIcon 
            style={{
              position:"relative",
              bottom:"55px",
              left:"35px",
            }}
          />}
        </div>
      </div>
      <Menu
        theme="light"
        defaultSelectedKeys={["0-0"]}
        mode="inline"
        items={items}
        onClick={handleMenuClick}
        style={{ background: "#fff" }}
        selectedKeys={[selectedKey]}
      />
      
    </Sider>
  );
}

