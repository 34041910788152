//-------------------- Import Components --------------------//

import { Tag, Space, Dropdown } from "antd";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../assets/rawSvg/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../assets/rawSvg/delete.svg";
import { ReactComponent as DropDownDots } from "../assets/rawSvg/dropdownDots.svg";
import { ReactComponent as ChangePassword } from "../assets/rawSvg/lock.svg";
import { ReactComponent as ViewDetailsIcon } from "../assets/rawSvg/eye.svg";
import { ReactComponent as MapUsersIcon } from "../assets/rawSvg/profile-2user.svg";
import QRCodeIcon from "../assets/icons/QRCodeIcon.png";
import { QRCodeSVG } from "qrcode.react";
import { hasPermission } from "./permissionChecker";


//-------------------- Dashboard Projects Table Columns --------------------//


  export const dashboardTableColumns = ({handleEditRow, handleDeleteRow}) => {
    let columns = [
      { title: 'Subactivity', dataIndex: 'subactivity', key: 'subactivity' },
      { title: 'Vegetative', dataIndex: 'vegetative', key: 'vegetative' },
      { title: 'C&D', dataIndex: 'candD', key: 'candD' },
      { title: 'Mixed', dataIndex: 'mixed', key: 'mixed' },
      { title: 'RACM', dataIndex: 'racm', key: 'racm' },
      { title: 'Goods', dataIndex: 'goods', key: 'goods' },
      { title: 'Leaners', dataIndex: 'leaners', key: 'leaners' },
      { title: 'Hangers', dataIndex: 'hangers', key: 'hangers' },
      { title: 'Stump', dataIndex: 'stump', key: 'stump' },
      { title: 'Vehicles', dataIndex: 'vehicles', key: 'vehicles' },
      { title: 'Vessels', dataIndex: 'vessels', key: 'vessels' },
      { title: 'HHW', dataIndex: 'hhw', key: 'hhw' },
      { title: 'E-Waste', dataIndex: 'eWaste', key: 'eWaste' },
      { title: 'Veg', dataIndex: 'veg', key: 'veg' },
      {
        title: "Actions",
        key: "action",
        render: (_, record) => (
          <div className="d-flex" style={{position:"relative", top:"0px", right:"18px"}}>
            <Space size="small">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteRow(record);
                }}
              >
                <DeleteIcon />
              </Button>
            </Space>
          </div>
        ),
      },
    ];
  
    return columns;
  };
  


//-------------------- User Roles Table Columns --------------------//

export const userRolesColumns =  [
        { title: 'Role Name', dataIndex: 'name', key: 'name' },
      ];


//-------------------- User Positions Table Columns --------------------//

export const userPositionsColumns = ({roleState,handleEditRow}) => {
  
  let columns = [
      { title: 'User Role', dataIndex: 'userType', key: 'userType' },
      { title: 'Position Name', dataIndex: 'name', key: 'name' },
      { title: 'Access Permission', dataIndex: 'platform_type', key: 'platform_type' },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <Space size="middle" className="d-flex">
              
              {record.role.can_add_positions ? (<Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>)
              : null}
              
            </Space>
          ),
        },
    ];

  return columns;
};



//-------------------- User Management Table Columns --------------------//

export const userManagementColumns = ({handleEditRow,handleChangePassword, handleResetID}) => {
  let columns = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Role', dataIndex: 'roleName', key: 'roleName' },
      { title: 'Position', dataIndex: 'positionName', key: 'positionName' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, record) =>
          record.status === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
        title: "Actions",
        key: "action",
        align: "center",
        render: (_, record) => {
          let items = [
            {
              label: (
                <div
                  style={{ padding: "2px 0px", width: "180px" }}
                  onClick={(e) => {
                    handleEditRow(record);
                    
                  }}
                >
                  <span style={{}}>
                    <EditIcon />
                  </span>
                  <span className="ms-2">Edit</span>
                  
                </div>
              ),
              key: "0",
            },
            {
              label: (
                <div
                  style={{ padding: "2px 0px" }}
                  onClick={(e) => {
                    
                      handleChangePassword(record);
          
                  }}
                >
                  <span style={{"padding": "0 4px"}} ><ChangePassword/></span>
                  <span  className="ms-2">Change Password</span>
                </div>
              ),
              key: "1",
            },
          ]
          if (record && record.device_id) {
            items.push({
              label: (
                <div
                  style={{ padding: "2px 0px" }}
                  onClick={(e) => {
                    handleResetID(record);
                  }}
                >
                  <span style={{}}><DeleteIcon/></span>
                  <span  className="ms-2">Reset Device Id</span>
                </div>
              ),
              key: "2",
            })
          }
          return <>
            <Dropdown
              menu={{
                items: items,
              }}
              trigger={["click"]}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
                  <DropDownDots />
                </Space>
              </a>
            </Dropdown>
            
          </>
        },
      },
    ];
  
  return columns;
};
  

//-------------------- Debris Type Table Columns --------------------//


export const debrisTypeColumns = ({handleEditRow}) => {
  let columns = [
      { title: 'Debris Name', dataIndex: 'name', key: 'name' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <Space size="middle" className="d-flex">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
            </Space>
          ),
      },
    ];

  return columns;
};


//-------------------- Truck Type Table Columns --------------------//

export const truckTypeColumns = ({handleEditRow}) => {
  let columns = [
      { title: 'Truck Type', dataIndex: 'type', key: 'type' },
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <Space size="middle" className="d-flex">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
            </Space>
          ),
      },
    ];

  return columns;
};


//-------------------- Sub Activity Table Columns --------------------//


export const subActivityColumns = ({handleEditRow}) => {
  let columns = [
      { title: 'Sub-Activity Name', dataIndex: 'name', key: 'name' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <Space size="middle" className="d-flex">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
            </Space>
          ),
      },
    ];

  return columns;
};


//-------------------- Hazard Management Table Columns --------------------//


export const hazardTypeColumns = ({handleEditRow}) => {
  let columns = [
      { title: 'Hazard Type', dataIndex: 'type', key: 'type' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <Space size="middle" className="d-flex">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
            </Space>
          ),
      },
    ];

  return columns;
};

export const hazardNameColumns = ({handleEditRow}) => {
  let columns = [
      { title: 'Hazard Type', dataIndex: 'hazardType', key: 'hazardType' },
      { title: 'Hazard Name', dataIndex: 'name', key: 'name' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <Space size="middle" className="d-flex">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
            </Space>
          ),
      },
    ];

  return columns;
};


//-------------------- Event Management Table Columns --------------------//


export const eventManagementColumns = ({handleEditRow, handleDeleteRow, assignedPermissionsArray}) => {
  let columns = [
      { title: 'Event Name', dataIndex: 'eventName', key: 'eventName' },
      { title: 'Event Date', dataIndex: 'eventDate', key: 'eventDate' },
      { title: 'Declaration Date', dataIndex: 'declarationDate', key: 'declarationDate' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <div className="d-flex" style={{position:"relative", top:"0px", right:"18px"}}>

            <Space size="small" >
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteRow(record);
                }}
              >
                <DeleteIcon />
              </Button>
            </Space>
            </div>
          ),
      },
    ];

  return columns;
};


//-------------------- Manage Projects Table Columns --------------------//


// export const manageProjectsColumns = ({handleViewDetails, handleMapUser}) => {
//   let columns = [
//       { title: 'Project Name', dataIndex: 'name', key: 'name' },
//       { title: 'Event Name', dataIndex: 'event_name', key: 'event_name' },
//       { title: 'Client Name', dataIndex: 'client_name', key: 'client_name' },
//       { title: 'Prime Contractor', dataIndex: 'prime_contractor', key: 'prime_contractor' },
//       { title: 'City', dataIndex: 'city', key: 'city' },
//       { title: 'State', dataIndex: 'state', key: 'state' },
//       {
//         title: "Status",
//         dataIndex: "is_active",
//         key: "is_active",
//         render: (_, record) =>
//           record.is_active === true ? (
//             <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
//               Active
//             </Tag>
//           ) : (
//             <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
//               InActive
//             </Tag>
//           ),
//       },
//       {
//         title: "Actions",
//         key: "action",
//         align: "center",
//         render: (_, record) => (
//           <>
//             <Dropdown
//               menu={{
//                 items: [
//                   {
//                     label: (
//                       <div
//                         style={{ width: "180px", padding: "2px 8px" }}
//                         onClick={(e) => {
//                           handleViewDetails(record);
//                         }}
//                       >
//                         <span className="me-3">
//                           <ViewDetailsIcon />
//                         </span>
//                         View Details
//                       </div>
//                     ),
//                     key: "0",
//                   },
//                   {
//                     label: (
//                       <div
//                         style={{ width: "180px", padding: "2px 8px" }}
//                         // className="d-flex justify-content-evenly"
//                         onClick={(e) => {
                          
//                           handleMapUser(record);
                
//                         }}
//                       >
//                         <span className="me-3"><MapUsersIcon/></span>
//                         Map Users
//                       </div>
//                     ),
//                     key: "1",
//                   },
//                 ],
//               }}
//               trigger={["click"]}
//             >
//               <a
//                 onClick={(e) => {
//                   e.preventDefault();
//                 }}
//               >
//                 <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
//                   <DropDownDots />
//                 </Space>
//               </a>
//             </Dropdown>
            
//           </>
//         ),
//       },
//     ];

//   return columns;
// };


export const manageProjectsColumns = ({ handleViewDetails, handleMapUser }) => {
  let columns = [
    { title: 'Project Name', dataIndex: 'name', key: 'name' },
    { title: 'Event Name', dataIndex: 'event_name', key: 'event_name' },
    { title: 'Client Name', dataIndex: 'client_name', key: 'client_name' },
    { title: 'Prime Contractor', dataIndex: 'prime_contractor', key: 'prime_contractor' },
    { title: 'City', dataIndex: 'city', key: 'city' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (_, record) =>
        record.is_active === true ? (
          <Tag bordered={false} color="green" style={{ borderRadius: "12px" }}>
            Active
          </Tag>
        ) : (
          <Tag bordered={false} color="red" style={{ borderRadius: "12px" }}>
            InActive
          </Tag>
        ),
    },
  ];

  if (handleMapUser && handleViewDetails) {
    columns.push({
      title: "Actions",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                label: (
                  <div
                    style={{ width: "180px", padding: "2px 8px" }}
                    onClick={(e) => {
                      handleViewDetails(record);
                    }}
                  >
                    <span className="me-3">
                      <ViewDetailsIcon />
                    </span>
                    View Details
                  </div>
                ),
                key: "0",
              },
              {
                label: (
                  <div
                    style={{ width: "180px", padding: "2px 8px" }}
                    onClick={(e) => {
                      handleMapUser(record);
                    }}
                  >
                    <span className="me-3">
                      <MapUsersIcon />
                    </span>
                    Map Users
                  </div>
                ),
                key: "1",
              },
            ],
          }}
          trigger={["click"]}
        >
          <a
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Space size="middle" className="cursorPointer" style={{ transform: "rotate(270deg)" }}>
              <DropDownDots />
            </Space>
          </a>
        </Dropdown>
      ),
    });
  }

  return columns;
};


//-------------------- Map User Table Columns --------------------//


export const mapUserColumns = ({handleEditRow, assignedPermissionsArray}) => {
  let columns = [
      { title: 'Project', dataIndex: 'name', key: 'name' },
      { title: 'No. of Admins', dataIndex: 'number_of_admins', key: 'number_of_admins' },
      { title: 'No. of Data Entry Staffs', dataIndex: 'number_of_data_entry_staffs', key: 'number_of_data_entry_staffs' },
      { title: 'No. of Data Supervisors', dataIndex: 'number_of_data_supervisors', key: 'number_of_data_supervisors' },
      { title: 'No. of Field Supervisors', dataIndex: 'number_of_field_supervisors', key: 'number_of_field_supervisors' },
      { title: 'No. of Site Supervisors', dataIndex: 'number_of_site_supervisors', key: 'number_of_site_supervisors' },
      {
        title: "Actions",
        key: "action",
        render: (_, record) => (
          <div className="d-flex" style={{position:"relative", top:"0px", right:"18px"}}>

          <Space size="small" >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                handleEditRow(record);
              }}
              // disabled={!hasPermission(assignedPermissionsArray, 'change_event')}
            >
              <EditIcon />
            </Button>
          </Space>
          </div>
        ),
      },
    ];

  return columns;
};


//-------------------- Update Map User Table Columns --------------------//


export const updateMapUserColumns = ({handleDeleteRow, assignedPermissionsArray}) => {
  let columns = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Position', dataIndex: 'position', key: 'position' },
      {
        title: "Actions",
        key: "action",
        render: (_, record) => (
          <div className="d-flex" style={{position:"relative", top:"0px", right:"18px"}}>

          <Space size="small" >
            <Button
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteRow(record);
              }}
              // disabled={!hasPermission(assignedPermissionsArray, 'change_event')}
            >
              <DeleteIcon />
            </Button>
          </Space>
          </div>
        ),
      },
    ];

  return columns;
};



//-------------------- Contractor Management Table Columns --------------------//


export const contractorManagementColumns = ({handleEditRow}) => {
  let columns = [
      { title: 'Company Name', dataIndex: 'company_name', key: 'company_name' },
      { title: 'Type', dataIndex: 'type', key: 'type' },
      { title: 'Prime Contractor', dataIndex: 'primeContractor', key: 'primeContractor' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <div className="d-flex" style={{position:"relative", top:"0px", right:"18px"}}>

            <Space size="small" >
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
            </Space>
            </div>
          ),
      },
    ];

  return columns;
}



//-------------------- Manage Rate Matrix Table Columns --------------------//

export const rateMatrixManagementColumns = ({handleEditRow, handleDeleteRow}) => {
  let columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Debris',
      dataIndex: 'debris',
      key: 'debris',
    },
    {
      title: 'Mileage',
      children: [
        {
          title: 'From',
          dataIndex: 'mileageFrom',
          key: 'mileageFrom',
        },
        {
          title: 'To',
          dataIndex: 'mileageTo',
          key: 'mileageTo',
        },
      ],
    },
    {
      title: 'Diameter',
      children: [
        {
          title: 'From',
          dataIndex: 'diameterFrom',
          key: 'diameterFrom',
        },
        {
          title: 'To',
          dataIndex: 'diameterTo',
          key: 'diameterTo',
        },
      ],
    },
    {
      title: 'Length',
      children: [
        {
          title: 'From',
          dataIndex: 'lengthFrom',
          key: 'lengthFrom',
        },
        {
          title: 'To',
          dataIndex: 'lengthTo',
          key: 'lengthTo',
        },
      ],
    },
    {
      title: 'Unit',
      children: [
        {
          title: 'From',
          dataIndex: 'unitFrom',
          key: 'unitFrom',
        },
        {
          title: 'To',
          dataIndex: 'unitTo',
          key: 'unitTo',
        },
      ],
    },
    {
      title: 'Weight',
      children: [
        {
          title: 'From',
          dataIndex: 'weightFrom',
          key: 'weightFrom',
        },
        {
          title: 'To',
          dataIndex: 'weightTo',
          key: 'weightTo',
        },
      ],
    },
    {
      title: 'Unit Type',
      dataIndex: 'unitType',
      key: 'unitType',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <Space size="middle" className="d-flex">
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>

              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteRow(record);
                }}
              >
                <DeleteIcon />
              </Button>
            </Space>
          ),
      },
    ];

  return columns;
}


//-------------------- Ticket Management Table Columns --------------------//

export const ticketManagementColumns = ({handleEditRow, handleVoidRow}) => {
  let columns = [
      { title: 'Ticket #', dataIndex: 'ticketNumber', key: 'ticketNumber' },
      { title: 'Manual Ticket #', dataIndex: 'manualTicketNumber', key: 'manualTicketNumber' },
      { title: 'Sub Activity', dataIndex: 'subActivity', key: 'subActivity' },
      { title: 'Truck Type', dataIndex: 'truckType', key: 'truckType' },
      { title: 'Project Name', dataIndex: 'projectName', key: 'projectName' },
      { title: 'Field Monitor Name', dataIndex: 'fieldMonitorName', key: 'fieldMonitorName' },
      { title: 'Site Monitor Name', dataIndex: 'siteMonitorName', key: 'siteMonitorName' },
      { title: 'Created Date', dataIndex: 'createdDate', key: 'createdDate' },
      { title: 'Modified Date', dataIndex: 'modifiedDate', key: 'modifiedDate' },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, record) =>
          record.status === "open" ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
            Open
          </Tag>
        ) : (
          <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
            Closed
          </Tag>
        ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle" className="d-flex">
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleEditRow(record);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleVoidRow(record);
            }}
          >
            <DeleteIcon />
          </Button>
        </Space>
      ),
    },
  ];

return columns;
}

//-------------------------- Disposal Site Management ---------------------//


export const disposalSiteManagementColumns = ({handleEditRow}) => {
  let columns = [
      { title: 'Disposal Site', dataIndex: 'name', key: 'name' },
      { title: 'Address', dataIndex: 'address', key: 'address' },
      { title: 'Type', dataIndex: 'type', key: 'type' },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (_, record) =>
          record.is_active === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <div className="d-flex" style={{position:"relative", top:"0px", right:"18px"}}>

            <Space size="small" >
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditRow(record);
                }}
              >
                <EditIcon />
              </Button>
            </Space>
            </div>
          ),
      },
    ];

  return columns;
}


//-------------------- Truck Management Table Columns --------------------//


export const truckManagementColumns = ({handleEditRow, handleDeleteRow, handleGenerateQRCode}) => {
  let columns = [
      { title: 'Truck #', dataIndex: 'truck_number', key: 'truck_number' },
      { title: 'Prime Contractor', dataIndex: 'primeContractor', key: 'primeContractor' },
      { title: 'Sub Contractor', dataIndex: 'subContractor', key: 'subContractor' },
      { title: 'Driver Name', dataIndex: 'driver_name', key: 'driver_name' },
      // { title: 'Created By', dataIndex: 'created_by', key: 'created_by' },
      // { title: 'Created Date', dataIndex: 'created_date', key: 'created_date' },
      {
        title: "Certification Status",
        dataIndex: "certification_status",
        key: "certification_status",
        render: (_, record) =>
          record.certification_status === true ? (
            <Tag bordered={false} color="green" style={{borderRadius:"12px"}}>
              Active
            </Tag>
          ) : (
            <Tag bordered={false} color="red" style={{borderRadius:"12px"}}>
              InActive
            </Tag>
          ),
      },
      // {
      //   title: "Truck Work Status",
      //   dataIndex: "truck_work_status",
      //   key: "truck_work_status",
      //   render: (_, record) =>
      //     record.truck_work_status === true ? (
      //       <Tag bordered={false} color="purple" style={{borderRadius:"12px"}}>
      //         Available
      //       </Tag>
      //     ) : (
      //       <Tag bordered={false} color="yellow" style={{borderRadius:"12px"}}>
      //         UnAvailable
      //       </Tag>
      //     ),
      // },
      // { title: 'Occupied By', dataIndex: 'occupied_by', key: 'occupied_by' },
      // { title: 'Occupied Date', dataIndex: 'occupied_date', key: 'occupied_date' },
      {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <div className="d-flex" style={{position:"relative", top:"0px", right:"18px"}}>
              <Space size="middle">
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEditRow(record);
                  }}
                >
                  <EditIcon />
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleGenerateQRCode(record);
                  }}
                >
                  <img src={QRCodeIcon} alt="QRCode" style={{width:"22px", height:"22px"}} />
                  {/* <QRCodeSVG scale={3} /> */}
                </Button>
                {/* <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteRow(record);
                  }}
                >
                  <DeleteIcon />
                </Button> */}
              </Space>

            </div>

          ),
      },
    ];

  return columns;
};


//-------------------- Reports Table Columns --------------------//

// Daily Summary Report Table Columns

export const dailySummaryReportColumns = [
  { title: 'Debris', dataIndex: 'debrisName', key: 'debrisName' },
  { title: 'Unit of Measure', dataIndex: 'unitOfMeasure', key: 'unitOfMeasure' },
  { title: 'Total Tickets', dataIndex: 'totalTickets', key: 'totalTickets' },
  { title: 'Ticket Value', dataIndex: 'totalValue', key: 'totalValue' },
];

// Disposal Site Report Table Columns

export const disposalSiteReportColumns = [
  { title: 'Ticket #', dataIndex: 'ticketNumber', key: 'ticketNumber' },
  { title: 'Truck #', dataIndex: 'truckNumber', key: 'truckNumber' },
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Debris Type', dataIndex: 'debrisType', key: 'debrisType' },
  { title: 'Unit of Measure', dataIndex: 'unitOfMeasure', key: 'unitOfMeasure' },
  { title: 'Ticket Value', dataIndex: 'totalValue', key: 'totalValue' },
];

export const listDisposalSiteReportColumns = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Type', dataIndex: 'siteType', key: 'siteType' },
  { title: 'Wacs', dataIndex: 'wacsNumber', key: 'wacsNumber' },
  { title: 'Address', dataIndex: 'address', key: 'address' },
  { title: 'Debris Types Accepted', dataIndex: 'debrisTypeAccepted', key: 'debrisTypeAccepted' },
];

export const debrisLoadReportColumns = [
  { title: 'Debris Type', dataIndex: 'debrisType', key: 'debrisType' },
  { title: 'Unit of Measure', dataIndex: 'unitOfMeasure', key: 'unitOfMeasure' },
  { title: 'Ticket Value', dataIndex: 'totalValue', key: 'totalValue' },
];

export const debrisCostReportColumns = [
  { title: 'Debris Type', dataIndex: 'debrisType', key: 'debrisType' },
  { title: 'Unit of Measure', dataIndex: 'unitOfMeasure', key: 'unitOfMeasure' },
  { title: 'Contractor Cost', dataIndex: 'contractorCost', key: 'contractorCost' },
];


//-------------------- Styled Button Component --------------------//
const Button = styled.button`
border: none;
background: none;
`;